<!-- ONE Button-->
<ng-container *ngIf="templateService.getOptionConfigKeys(filterAllowToAddOptions).length==1">
    <!--standard button-->
    <div *ngIf="appearance!=='fab' && appearance!=='icon' && appearance!=='stroked'"
        class='appearance-standard add-button-container'>
        <button mat-raised-button [color]="color" tabindex="0"
            (click)="addOption(templateService.getOptionConfigKeys(filterAllowToAddOptions)[0])">
            <mat-icon class="add-button-icon">add_circle</mat-icon>
            <span class="add-button-label">{{'options.buttonAdd' | transTemplate | async}}</span>
        </button>
    </div>
    <!--stroked button-->
    <div *ngIf=" appearance=='stroked'" class='appearance-stroked add-button-container'>
        <button mat-stroked-button (click)="addOption(templateService.getOptionConfigKeys(filterAllowToAddOptions)[0])"
            tabindex="0">
            <mat-icon class="add-button-icon">add_circle</mat-icon>
            <span class="add-button-label">{{'options.buttonAdd' | transTemplate | async}}</span>
        </button>
    </div>
    <!--icon button-->
    <span *ngIf=" appearance=='icon'" class='appearance-icon button-container' tabindex="0">
        <button mat-icon-button class="navigation-button" color="primary"
            (click)="addOption(templateService.getOptionConfigKeys(filterAllowToAddOptions)[0])"
            matTooltip="{{'options.buttonAdd' | transTemplate | async}}">
            <mat-icon class="add-button-icon">add</mat-icon>
        </button>
    </span>
    <!--fab button-->
    <div *ngIf=" appearance=='fab'" class='appearance-icon button-container' tabindex="0">
        <button mat-fab class="navigation-button" [color]="color"
            (click)="addOption(templateService.getOptionConfigKeys(filterAllowToAddOptions)[0])"
            matTooltip="{{'options.buttonAdd' | transTemplate | async}}">
            <mat-icon class="add-button-icon">add</mat-icon>
        </button>
    </div>
</ng-container>

<!-- Multiple Buttons-->
<ng-container *ngIf="templateService.getOptionConfigKeys(filterAllowToAddOptions).length >1 ">
    <!--standard button-->
    <div *ngIf="appearance!=='fab' && appearance!=='icon' && appearance!=='stroked'"
        class='appearance-standard add-button-container' tabindex="0">
        <button mat-raised-button [color]="color" [matMenuTriggerFor]="menu">
            <mat-icon class="add-button-icon">add_circle</mat-icon>
            <span class="add-button-label">{{'options.buttonAdd' | transTemplate | async}}</span>
        </button>
    </div>
    <!--stroked button-->
    <div *ngIf=" appearance=='stroked'" class='appearance-stroked add-button-container'>
        <button mat-stroked-button [matMenuTriggerFor]="menu" tabindex="0">
            <mat-icon class="add-button-icon">add_circle</mat-icon>
            <span class="add-button-label">{{'options.buttonAdd' | transTemplate | async}}</span>
        </button>
    </div>
    <!--icon button-->
    <span *ngIf=" appearance=='icon'" class='appearance-icon button-container'>
        <button mat-icon-button class="navigation-button" color="primary" [matMenuTriggerFor]="menu"
            matTooltip="{{'options.buttonAdd' | transTemplate | async}}" tabindex="0">
            <mat-icon class="add-button-icon">add</mat-icon>
        </button>
    </span>
    <!--fab button-->
    <div *ngIf=" appearance=='fab'" class='appearance-icon button-container'>
        <button mat-fab class="navigation-button" [color]="color" [matMenuTriggerFor]="menu"
            matTooltip="{{'options.buttonAdd' | transTemplate | async}}" tabindex="0">
            <mat-icon class="add-button-icon">add</mat-icon>
        </button>
    </div>

    <!--menu-->
    <mat-menu #menu="matMenu">
        <button *ngFor="let configKey of templateService.getOptionConfigKeys(filterAllowToAddOptions)" mat-menu-item
            (click)="addOption(configKey)">
            <span>{{templateService.getOptionConfigKeyLabel(configKey)}}</span>
        </button>
    </mat-menu>
</ng-container>
