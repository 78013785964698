import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NGXLogger as LoggerService } from "ngx-logger";
import { HrefLangMapping, languageCodes } from '../shared/services/route-context.service';
import { blogAlternateHrefLangs } from '../locale/blog-href-mapping';





@Injectable({
    providedIn: 'root',
})
/**
 */
export class BlogGeneralService {

    constructor(
        private logger: LoggerService,
        @Inject(LOCALE_ID) public locale: string,
        private router: Router,


    ) {
    }

    get useBlogger() {
        if (this.locale === "en" || this.locale === "de") {
            return true;
        } else {
            return false;
        }
    }

    public getAlternateHrefLangs(): HrefLangMapping {
        const currentHrefKey = this.router.url.substring('blog/'.length + 1);
        this.logger.log('getAlternateHrefLangs currentHrefKey', currentHrefKey);
        let matchingMapping: HrefLangMapping;
        blogAlternateHrefLangs.forEach((mapping, index) => {
            this.logger.log('mapping', mapping)
            if (mapping[this.locale]?.href === currentHrefKey) {
                matchingMapping = { ...mapping };
            }
        })
        this.logger.log("matchingMapping", matchingMapping)
        let returnMapping: HrefLangMapping = this.deepCopy(matchingMapping);
        languageCodes.forEach(languageCode => {
            if (matchingMapping && matchingMapping[languageCode]) {
                returnMapping[languageCode].href = "blog/" + matchingMapping[languageCode].href

            }
        })
        return returnMapping;
    }

    private deepCopy(hrefLangMapping: HrefLangMapping): HrefLangMapping {
        if (hrefLangMapping) {
            return JSON.parse(JSON.stringify(hrefLangMapping));
        } else {
            return null
        }
    }



    // not form blog service to reduce depenency to blog module
    public getCategories() {
        const categories = ["Blog", "How to", "FAQ"];
        return categories
    }

    public getCategoryRoutePath(category: string): string {
        return '/blog/category/' + category;
        //  return "/blog/" + category;
    }


    getKeywordRoutePath(keyword: string): string {
        return '/blog/keyword/' + keyword;
        //  return "/blog/" + category;
    }

}
