<div *ngIf="payInitiated">
    <div class="spinner-container">
        <mat-progress-spinner class="spinner" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>

<mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="productSelected">
    <mat-radio-button class="radio-button" *ngFor="let  product of products" [value]="product.uniqueKey">
        <div class="radio-label">
            <span class="product-description">{{getProductDescription(product)}}</span><span class="product-total">
                {{product.total}}
                USD</span>
        </div>
    </mat-radio-button>
</mat-radio-group>
<div class="checkbox-container">

    <mat-checkbox class="checkbox" [(ngModel)]="termsConfirmed">
        <div class="check-box-label"><span>I herby confirm
                to agree with the</span>&nbsp;<a (click)="openTerms()">terms and conditions</a>.
        </div>
    </mat-checkbox>

</div>


<div class='pay-button-container'>
    <button mat-stroked-button (click)="pay(productSelected)"
        [disabled]="(!productSelected || termsConfirmed!==true)">Pay
        with
        Paypal</button>
</div>