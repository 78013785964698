import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { LoggerService } from '../core/logger.service';
import { ResponsiveService } from '../core/responsive.service';
import { EssError, EssErrorService } from '../core/error-service';

export interface EssFaq {
    "question": string;
    "answer": string
};

@Component({
    selector: 'ess-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatExpansionModule,
        MatIconModule
    ]
})

export class EssFaqComponent implements OnInit {
    @Input() faqs: EssFaq[]
    constructor(
        public responsiveService: ResponsiveService,
        private logger: LoggerService,
        private renderer: Renderer2,

        private errorService: EssErrorService
    ) {
    }

    ngOnInit(): void {
        //    this.faqs = this.getSampleFaqs();
        if (this.faqs && Array.isArray(this.faqs)) {
            this.addStructuredData();
        } else {
            throw (this.errorService.newError('No Faq array'))
        }
    }
    getSampleFaqs(): EssFaq[] {
        return [
            {
                question: "What is the dress code for the birthday party?",
                answer: "The dress code is casual. Feel free to wear something comfortable."
            },
            {
                question: "Will there be food and drinks provided?",
                answer: "Yes, there will be a variety of food and drinks available for all guests."
            },
            {
                question: "Can I bring a plus one?",
                answer: "Yes, you are welcome to bring a plus one. Please let us know in advance."
            },
            {
                question: "Is there a theme for the birthday party?",
                answer: "Yes, the theme is 'Retro 80s'. Feel free to dress accordingly."
            },
            {
                question: "What time does the party start?",
                answer: "The party starts at 7 PM. Please try to arrive on time."
            }
        ];
    }
    addStructuredData() {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                ${this.faqs.map(faq => `
                  {
                    "@type": "Question",
                    "name": "${faq.question}",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${faq.answer}"
                    }
                  }
                `).join(',')}
              ]
            }
            `;
        this.renderer.appendChild(document.head, script);
    }

}
