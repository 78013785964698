import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';

import { ResponsiveService } from '../../../shared/services/responsive.service';
import { TranslationGeneralService, languages, language } from '../../../shared/translation/translation.general.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { LanguageCheckDialog } from '../language-check-dialog/language-check-dialog.component';
import { EssDialogService } from 'ngx-essentia';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '../../../whoo/core/navigation.service';

@Component({
    selector: 'language-check',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        LanguageCheckDialog

    ],
    templateUrl: './language-check.component.html',
    styleUrls: ['./language-check.component.scss'],
})
export class LanguageCheck implements OnInit {
    private userLang: string;
    private localStorageKey = "whooLanguageDialog"
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        public responsiveService: ResponsiveService,
        public trans: TranslationGeneralService,
        private logger: LoggerService,
        private dialogService: EssDialogService,
        public nav: NavigationService
    ) { }


    ngOnInit() {
        this.userLang = this.getUsersLocale('en');
        this.logger.log('Language', this.userLang, window.localStorage.getItem(this.localStorageKey));
        setTimeout(() => {
            this.logger.log('timeout');
            if (!this.nav.isBlogBlogPost) { // redirect to another language does not work for blog posts
                if (this.locale !== this.userLang) {
                    if (this.trans.isLanguageImplemented(this.userLang)) {
                        if (window.localStorage.getItem(this.localStorageKey) !== "1") {
                            this.openLanguageCheckDialog();
                        }
                    }
                }
            }
        }, 30 * 1000);
    }

    private getUsersLocale(defaultValue: string): string {
        if (
            typeof window === 'undefined' ||
            typeof window.navigator === 'undefined'
        ) {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        return lang.slice(0, 2).toLowerCase()
    }

    public openLanguageCheckDialog(): void {
        import('../language-check-dialog/language-check-dialog.component').then((m) => {
            const dialogRef = this.dialogService.openSmall(m.LanguageCheckDialog, { userLang: this.userLang, localStorageKey: this.localStorageKey });
            dialogRef.afterClosed().subscribe((result) => {
                console.log('LanguageCheckDialog dialog was closed', result);
                if (result) {
                    this.logger.log('result: ', result);
                    this.nav.openWindowLanguage(this.userLang)
                } else {
                    this.logger.log('Closed without result - hence canceled: ');
                }
            });
        });
    }
}
