<div *ngIf="bannerService.showBanner" class="component-container">


    <!--big screen-->
    <ng-container *ngIf="responsive.isScreenBig()">
        <div class="content-row">

            <div *ngIf="showIcon" class="icon"><mat-icon>{{bannerService.icon}}</mat-icon></div>
            <div class="message">{{bannerService.message}}</div>
            <div class="buttons">
                <button mat-button *ngIf="showLeftButton" class="left-button" color="accent"
                    (click)="handleLefttButtonClick()">{{bannerService.leftButtonLabel}}</button>
                <button mat-button class="right-button" color="accent"
                    (click)="handleRightButtonClick()">{{bannerService.rightButtonLabel}}</button>
            </div>
        </div>
    </ng-container>


    <!--small screen-->
    <ng-container *ngIf="responsive.isScreenSmall()">
        <div class="content-row">
            <div *ngIf="showIcon" class="icon"><mat-icon>priority_high</mat-icon></div>
            <div class="message">{{bannerService.message}}</div>
        </div>
        <div class="buttons">
            <button mat-button *ngIf="showLeftButton" class="left-button" color="accent"
                (click)="handleLefttButtonClick()">{{bannerService.leftButtonLabel}}</button>
            <button mat-button class="right-button" color="accent"
                (click)="handleRightButtonClick()">{{bannerService.rightButtonLabel}}</button>
        </div>
    </ng-container>


</div>