<div [ngClass]="responsiveService.getBigSmall()">

    <div *ngIf="responsiveService.isScreenBig()">
        <div class="row" *ngIf="!imageRight">
            <div class="column left picture-big-screen" (click)="showImage()"
                matTooltip="{{ 'click-to-enlarge'  | transMigrated}}">
                <img src="{{imageUrl}}" alt="{{title}}">
            </div>
            <div class="column right ">
                <h3 class="first-h3">{{title}}</h3>
                <p>{{description}}</p>
            </div>
        </div>
        <div class="row" *ngIf="imageRight">
            <div class="column left">
                <h3>{{title}}</h3>
                <p>{{description}}</p>
            </div>
            <div class="column right picture-big-screen" (click)="showImage()"
                matTooltip="{{ 'click-to-enlarge'  | transMigrated}}">
                <img src="{{imageUrl}}" alt="{{title}}">
            </div>
        </div>
    </div>

    <div *ngIf="responsiveService.isScreenSmall()">
        <div class="row">
            <div class="column left-text">
                <h3>{{title}}</h3>
                <p>{{description}}</p>
                <div class="column image-container">
                    <img src="{{imageUrl}}" alt="{{title}}">
                </div>
            </div>
        </div>
    </div>
</div>