import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ResponsiveService } from '../../../../shared/services/responsive.service';

@Component({
    selector: 'founder-statement',
    templateUrl: './founder-statement.html',
    standalone: true,
    imports: [CommonModule, MatDividerModule],
    styleUrls: ['./founder-statement.scss'],
})
export class FounderStatementComponent {
    @Input() headline = '';
    @Input() paragraph1 = '';
    @Input() paragraph2 = '';
    @Input() name = '';
    @Input() imgSrc = '';

    constructor(public responsiveService: ResponsiveService) { }
}
