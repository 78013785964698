import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { firstValueFrom, Subscription } from 'rxjs';
import { NGXLogger as LoggerService } from "ngx-logger";
import firebase from 'firebase/compat/app';
import { DbUser, Delegation as DelegationRecord } from 'functions-lib';
import { EssErrorService } from 'ngx-essentia';
const DB_PATH_TO_USERS = 'users/';




/**
 * Provides additional user information that is NOT stored in afAuthUser in FireabaseAuthService
 * We do not duplicate the informaion from afAuthUser so we dont get any sync challenges
 * currentDbUser is getting loaded and updeated because we trigger subscribeDbUser in  FirebaseUserSERvice - that is a bit tricky
 */
@Injectable({
    providedIn: 'root'
})
export class UserService {
    public dbUser: DbUser;
    dbUserSubscription: Subscription;
    constructor(
        private afd: AngularFireDatabase,
        private logger: LoggerService,
        private errorService: EssErrorService,
        @Inject(LOCALE_ID) private locale: string,

    ) {
    }

    /**
     * (re-)subscribe to the dbUser in case the uid of afAuthUser has changed
     * Should be called ONLY from AuthService when the authuser changed
     * @param uid
     */
    public subscribeDbUser(uid: string) {
        // re-subscribe only if the Uid as cnahge compared to the last time¨
        if (this.dbUserSubscription) { this.dbUserSubscription.unsubscribe(); }
        this.dbUserSubscription = this.getDbUserObservable(uid)?.subscribe(dbUser => {
            if (dbUser) {
                this.logger.log('subscribeDbUser: ', dbUser);
                this.dbUser = dbUser;
            } else {//  so we create the db user
                // this can happen if login with popup did not nwork and we do login with redirect
                this.logger.log('subscribeDbUser: no dbuser', uid);
                this.createAndLoadDbUser(uid);
            }
        });

    }

    getDbUserObservable(uid: string) {
        if (uid) {
            return this.getUserRef(uid).valueChanges();
        }
    }

    public async createAndLoadDbUser(uid: string): Promise<void> {
        this.logger.log('syncAndLoadDbUser: updating...', uid);
        try {
            if (!uid) {
                throw this.errorService.newError('uid is null.')
            }
            await this.createDbUser(uid);
            // now make sure we actually load the user
            // we need that for example if we want to set a delegatedTo right
            // after merging the acount other wise the user would not be loaded
            // and the check for existing delgate would go wrong
            await this.loadDbUser(uid);
            return;
        } catch (error) {
            const message = 'Failed to update the profile (uid: ' + uid + " - " + error + ')';
            this.logger.error(message);
            //       throw (error);
        }
    }

    private async createDbUser(uid: string): Promise<void> {
        this.logger.log('updateUserDoc: updating...', uid);
        const secretKey = this.generateSecretKey();
        const userDataUpdate: DbUser = {
            uid: uid,
            language: this.locale,
            secretKey: secretKey,
        };
        await this.persistDbUser(userDataUpdate, uid);
        return;
    }

    private generateSecretKey() {
        // this is not 100% ungessable but should be good enough for our purpose
        // for details check : https://firebase.blog/posts/2015/02/the-2120-ways-to-ensure-unique_68
        //    const aflistRef: AngularFireList<any> = this.afd.list(DB_PATH_TO_USERS);
        //   const key = aflistRef.push({}).key
        //  return key;
        // return firebase.database.ServerValue.TIMESTAMP
        let d = new Date().getTime();// Timestamp
        let d2 = ((typeof performance !== "undefined") &&
            performance.now && (performance.now() * 1000)) || 0;// Time in microseconds since page-load or 0 if unsupported
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            let r = Math.random() * 16;// random number between 0 and 16
            if (d > 0) {// Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {// Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    private async persistDbUser(userDataUpdate: DbUser, uid: string): Promise<void> {
        if (uid) {
            return await this.getUserRef(uid)?.update(userDataUpdate);
        }
    }

    public async loadDbUser(uid: string): Promise<void> {
        if (uid) {
            this.logger.log('loadDbUser', uid);
            this.dbUser = await firstValueFrom(this.getDbUserObservable(uid));
            this.logger.log('loadDbUser loaded', this.dbUser);
        }
        return;
    }

    private getUserRef(uid: string): AngularFireObject<DbUser> {
        return this.afd.object(`${DB_PATH_TO_USERS}${uid}`);
    }

    public async getDelegationRecord(uid: string): Promise<DelegationRecord> {
        const secretKey = this.dbUser.secretKey;
        const newDelegation: DelegationRecord = {
            delegatedFromUid: this.dbUser.uid,
            secretKey: secretKey
        };
        return newDelegation;
    }

    get secretKey() {
        return this.dbUser?.secretKey
    };
    /**
     *
     * @param dbUser the user on which the delegation should be set - we neen this to chekc if there was a delegation before
     * a new delegationRecord triggers abackend faunction 'setDelegationCustomClaim' that set the custom claim on the authUser
     * @param newDelegationRecord
     */
    public async setNewDelegationRecord(newDelegationRecord: DelegationRecord, uid: string): Promise<void> {
        await this.loadDbUser(uid);
        if (this.dbUser) {
            let usersDelegationRecords: DelegationRecord[];
            this.logger.log('setDelegationFrom ', newDelegationRecord, this.dbUser.delegationFrom);
            if (this.dbUser.delegationFrom !== null && this.dbUser.delegationFrom !== undefined) {
                this.logger.log('this.dbUser.delegationFrom', this.dbUser.delegationFrom);
                usersDelegationRecords = this.dbUser.delegationFrom;
                if (!this.delegatinonRecordExistAlready(usersDelegationRecords, newDelegationRecord)) {
                    this.logger.log('delegatinonRecord does not exist yet')
                    usersDelegationRecords.push(newDelegationRecord);
                } else {
                    this.logger.log('delegatinonRecordExistAlready')
                }
            } else {
                this.logger.log('!this.dbUser.delegationFrom');
                usersDelegationRecords = [newDelegationRecord];
            }
            this.dbUser.delegationFrom = usersDelegationRecords;
            await this.persistDbUser({ delegationFrom: usersDelegationRecords }, uid);
            await this.loadDbUser(uid);
            this.logger.log('setDelegationFrom persistDbUser', this.dbUser, this.dbUser.delegationFrom);
        } else {
            this.logger.log('setDelegationFrom no dbUser')
        }
        return;
    }

    private delegatinonRecordExistAlready(delegationRecords: DelegationRecord[], delegationRecord: DelegationRecord): boolean {
        const findIndex = delegationRecords.findIndex(value =>
        (value.delegatedFromUid === delegationRecord.delegatedFromUid &&
            value.secretKey === delegationRecord.secretKey))
        this.logger.log('findindx', findIndex, delegationRecord, delegationRecords)
        if (findIndex === -1) {
            this.logger.log('findindx false')
            return false;
        } else {
            this.logger.log('findindx true')
            return true;
        }
    }

    public getSecretKey(uid?: string): string {
        if (!uid || uid === this.dbUser?.uid) {
            if (!this.dbUser.secretKey) {
                throw (this.errorService.newError("No secret key for own user id: " + uid))
            }
            return this.dbUser.secretKey
        }
        const findResult = this.dbUser.delegationFrom?.find(value => value.delegatedFromUid === uid)
        if (findResult) {
            return findResult.secretKey
        }
        throw (this.errorService.newError("Could not find secret key for: " + uid))
    }

    public logout() {
        this.dbUser = null;
        if (this.dbUserSubscription) {
            this.logger.log('dbUserSubscription.unsubscribe()');
            this.dbUserSubscription.unsubscribe();
        }
    }

    /**
     * Sets a time stamp so that the firebase functions job will delete the user after one or two days
     */
    public async markForDeletion(uid: string): Promise<void> {
        this.logger.log('markForDeletion');
        const userDataUpdate: DbUser = {
            markedForDeletion: firebase.database.ServerValue.TIMESTAMP
        };
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        await userRef.update(userDataUpdate);
    }

    public async unMarkForDeletion(uid: string): Promise<void> {
        this.logger.log('unMarkForDeletion ');
        const userDataUpdate: DbUser = {
            markedForDeletion: null,
        };
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        await userRef.update(userDataUpdate);
    }
}
