import { Pipe, PipeTransform } from "@angular/core";
import { EssTranslationService } from "../../../../../../dist/ngx-essentia";
import { TranslationGeneralService } from "./translation.general.service";

@Pipe({
    standalone: true,
    name: 'transMigrated',
})
export class TranslateMigratedPipe implements PipeTransform {
    constructor(
        private essTrans: EssTranslationService,
        private trans: TranslationGeneralService

    ) { }

    transform(keys: string) {
        return this.trans.translateMigrated(keys)
    }
}
