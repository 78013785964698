import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ResponsiveService } from './responsive.service';

@Injectable({
    providedIn: 'root'
})
export class SnackService {
    snackBarRef: MatSnackBarRef<SimpleSnackBar>;
    constructor(
        private snackBar: MatSnackBar,
        private responsiveService: ResponsiveService
    ) { }

    public config(panelClasses: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = [panelClasses];
        this.responsiveService.isScreenSmall() ? config.verticalPosition = 'bottom' : config.verticalPosition = 'top';
        return config;
    }


    open(message: string, config?: MatSnackBarConfig<any>, action?: string, verticalPositionBigScreen?: "top" | "bottom") {
        if (config) {
            config.duration = 6000;
            this.responsiveService.isScreenSmall() ? config.verticalPosition = 'bottom' : config.verticalPosition = 'top';
            if (verticalPositionBigScreen) config.verticalPosition = verticalPositionBigScreen
        }
        this.snackBar.open(message, action ? action : 'Ok', config ? config : { duration: 8000 });
    }
    openOfflineMessage(message) {
        const config = this.config('warning');
        this.snackBarRef = this.snackBar.open(message, '', config);
    }

    closeOfflineMessage() {
        console.log('closeOfflineMessage');
        if (this.snackBarRef) {
            console.log('this.snackBarRef');
            this.snackBarRef.dismiss();
        }
    }
    dismiss() {
        this.snackBar.dismiss();
    }

}
