import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressBarModule, ProgressBarMode } from '@angular/material/progress-bar';

@Component({
    selector: 'app-progress-bar',
    standalone: true,

    imports: [
        CommonModule,
        MatProgressBarModule
    ],

    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
    color = 'warn';
    mode = <ProgressBarMode>'indeterminate';
}
