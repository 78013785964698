
import { HeaderData } from "whocan-lib";

import { TemplateKey } from "./template-keys"
export { TemplateKey as TemplateKey };

type TemplateConfig = {
    general?: {
        inheritFrom?: string;
        icon?: string;
        previewImage?: string;
        show?: boolean; // to indicate if the template should be displayed on landing pages creaete poll dialog
        formToUse?: string,
        defaultOptions?: string,
        useStepper?: boolean,
        useOptionsListPreview?: boolean,

    };

    availableSettings?: {
        pollDescription?: boolean;
        when?: boolean;
        whenAdvanced?: boolean,
        location?: boolean;
        locationAdvanced?: boolean,
        advanced?: boolean
        respondTill?: boolean;
        chooseTheme?: boolean;
        publicComments?: boolean;
        multiplePersons?: boolean;
        kids?: boolean
        vegetariansForMultiplePersons?: boolean;
        multiplePersonsAdvanced?: boolean;
        kidsAdvanced?: boolean
        vegetariansForMultiplePersonsAdvanced?: boolean;
        vegetarians?: boolean;
        vegetariansAdvanced?: boolean;
        privateComments?: boolean;
        askForEmailAdress?: boolean;
        askForPhoneNumber?: boolean;
        hideParticipants?: boolean;
        getNotifications?: boolean;
        dateRelated?: boolean;
        allowToAddOptions?: boolean;
    }
    defaultSettings?: HeaderData //  -> be aware that single attributes of the default settings are not inherited, the whole defaultSettings object need to be configured per template
    options?: {
        skipOptionsStep?: boolean;
        showAddDate?: boolean;
        showAddText?: boolean;
        showDescription?: boolean;
        showMaxParticipants?: boolean;
        optionTypes?: string[],
        showOptionTypeIcon?: boolean
    };
    responsesDashboard?: {
        showChartNumberOfReponses?: boolean;
        showDaysSinceCreation?: boolean;
        showChartBestDateOptions?: boolean;
        showChartBestTextOptions?: boolean;
        showChartRegisterPeople?: boolean;
    };
}


export const templateConfigMap = new Map<string, TemplateConfig>([
    [TemplateKey.advanced, {
        general: {
            icon: 'settings',
            previewImage: 'advanced-tools.jpg',
            show: false,
            formToUse: 'genericWithTheme',
            defaultOptions: '',
            useStepper: true,
            useOptionsListPreview: true,


        },
        availableSettings: {
            advanced: true,
            location: false,
            when: false,
            pollDescription: true,
            dateRelated: false,
            hideParticipants: true,
            locationAdvanced: true,
            whenAdvanced: true,
            allowToAddOptions: true,
            getNotifications: true,
            chooseTheme: true,
            multiplePersons: false,
            kids: false,
            vegetariansForMultiplePersons: false,
            vegetarians: false,
            multiplePersonsAdvanced: true,
            kidsAdvanced: true,
            vegetariansForMultiplePersonsAdvanced: true,
            vegetariansAdvanced: false,
            respondTill: true,
            askForEmailAdress: true,
            askForPhoneNumber: true,
            privateComments: true,
            publicComments: false,
        },
        options: {
            skipOptionsStep: false,
            showAddDate: true,
            showAddText: true,
            showDescription: true,
            showMaxParticipants: true,
            optionTypes: ['date', 'date_willJoin', 'divider', 'text', 'text_text', 'text_slider', 'textAndNumber_textAndNumber'],
            showOptionTypeIcon: true,
        },
        defaultSettings: {
            theme: 'no-theme',
            showAdvancedSettings: true,
        },
        responsesDashboard: {
            showChartNumberOfReponses: true,
            showDaysSinceCreation: true,
            showChartBestDateOptions: true,
            showChartBestTextOptions: true,
            showChartRegisterPeople: true
        }
    },],
    [TemplateKey.advancedNew, {
        general: {
            inheritFrom: TemplateKey.advanced,
            show: false,
        }
    },],
    [TemplateKey.schedule, {
        general: {
            inheritFrom: TemplateKey.advanced,
            icon: 'date_range',
            previewImage: '/poll-preview/calendar.webp',
            show: true,
            useOptionsListPreview: false,

        },
        availableSettings: {
            multiplePersons: false,
            chooseTheme: true,
            location: false,
            locationAdvanced: true,
            when: false,
            whenAdvanced: false,
            respondTill: true,
        },
        defaultSettings: {
            theme: 'calendar',
        },
        options: {
            showDescription: true,
            showMaxParticipants: false,
            optionTypes: ['date'],
            showOptionTypeIcon: false,

        },
    },],
    [TemplateKey.scheduleNew, {
        general: {
            inheritFrom: TemplateKey.schedule,
            show: false,
        },
    },],
    [TemplateKey.doodleAlternative, {
        general: {
            inheritFrom: TemplateKey.schedule,
            previewImage: 'doodle-poll.png',
            icon: 'date_range',
            show: false,
        }
    },],
    [TemplateKey.doodleAlternativeNew, {
        general: {
            inheritFrom: TemplateKey.doodleAlternative,
        },
    },],
    [TemplateKey.participation, {
        general: {
            inheritFrom: TemplateKey.advanced,
            icon: 'how_to_reg',
            previewImage: '/generic-poll/register.webp',
            show: true,
        },
        availableSettings: {
            chooseTheme: true,
            location: false,
            locationAdvanced: true,
            when: false,
            whenAdvanced: false,
            respondTill: true,
            multiplePersons: false,
            vegetariansForMultiplePersons: false,
            allowToAddOptions: false,

        },
        defaultSettings: {
            theme: 'registration-form',
            hideParticipants: true,
        },
        options: {
            optionTypes: ['date_willJoin', 'text'],
            showOptionTypeIcon: true,
            showDescription: false,
            showMaxParticipants: true,
        },
    },],
    [TemplateKey.participationNew, {
        general: {
            inheritFrom: TemplateKey.participation,
            show: false,
            useStepper: true

        },
    },],
    [TemplateKey.invitation, {
        general: {
            inheritFrom: TemplateKey.advanced,
            icon: 'mark_email_read',
            previewImage: 'poll-preview/invitation.webp',
            show: true,
            formToUse: 'invitation',
            useStepper: true
        },

        availableSettings: {
            pollDescription: true,
            when: true,
            advanced: true,
            location: true,
            whenAdvanced: false,
            locationAdvanced: false,
            respondTill: true,
            chooseTheme: true,
            multiplePersons: true,
            kids: true,
            vegetariansForMultiplePersons: true,
            vegetarians: false,
            multiplePersonsAdvanced: false,
            kidsAdvanced: false,
            vegetariansForMultiplePersonsAdvanced: false,
            vegetariansAdvanced: false,
            publicComments: false,
            getNotifications: true,
            hideParticipants: true,
            dateRelated: false,
            allowToAddOptions: false,
        },
        defaultSettings: {
            theme: 'ballons',
        },
        options: {
            skipOptionsStep: true,
            showDescription: true,
            showMaxParticipants: true,
            optionTypes: ['text', 'text_text',],
        },
    },],
    [TemplateKey.birthdayInvitation, {
        general: {
            inheritFrom: TemplateKey.invitation,
            show: false,
        }
    },],
    [TemplateKey.duties, {
        general: {
            inheritFrom: TemplateKey.advanced,
            previewImage: '/poll-preview/duties.webp',
            icon: 'checklist',
            show: true,
            useOptionsListPreview: false,

        },
        availableSettings: {
            multiplePersons: false,
            chooseTheme: true,
            location: false,
            locationAdvanced: true,
            when: false,
            whenAdvanced: false,
            respondTill: true,
        },
        defaultSettings: {
            allowToAddOptions: true,
            theme: 'duties',
        },
        options: {
            optionTypes: ['date', 'text'],
            showDescription: true,
            showMaxParticipants: true,
            showOptionTypeIcon: false,
        },
    },],

    [TemplateKey.dutiesDate, {
        general: {
            inheritFrom: TemplateKey.duties,
            show: false,
        },
    }],
    [TemplateKey.dutiesNew, {
        general: {
            inheritFrom: TemplateKey.duties,
            show: false,
            useStepper: true

        }
    },],
    [TemplateKey.potluck, {
        general: {
            inheritFrom: TemplateKey.advanced,
            icon: 'flatware',
            previewImage: '/poll-preview/potluck.webp',
            show: true,
            defaultOptions: 'potluck',
            useOptionsListPreview: false,

        },
        availableSettings: {
            chooseTheme: true,
            multiplePersons: true,
            kids: true,
            vegetariansForMultiplePersons: true,
            vegetarians: false,
            multiplePersonsAdvanced: false,
            kidsAdvanced: false,
            vegetariansForMultiplePersonsAdvanced: false,
            vegetariansAdvanced: false,
            location: true,
            locationAdvanced: false,
            when: true,
            whenAdvanced: false,
            respondTill: false,
            allowToAddOptions: false,
        }
        ,
        defaultSettings: {
            theme: 'potluck',
            allowToAddOptions: false,
            askForMultiplePersons: true,
            askForKids: false,
            askForPrivateComments: true
        },
        options: {
            optionTypes: ['textAndNumber_textAndNumber'],
            showDescription: true,
            showMaxParticipants: true,
            showOptionTypeIcon: false,


        },
        responsesDashboard: {
            showChartBestDateOptions: false,
            showChartBestTextOptions: false,
        }

    }],
    [TemplateKey.potluckNew, {
        general: {
            inheritFrom: TemplateKey.potluck,
            show: false,
            useStepper: true,
            defaultOptions: ''
        }
    },],
    [TemplateKey.preference, {
        general: {
            inheritFrom: TemplateKey.advanced,
            icon: 'poll',
            previewImage: '/generic-poll/human-hands.webp',
            show: true,
            defaultOptions: 'preference'

        },
        availableSettings: {
            multiplePersons: false,
            chooseTheme: true,
            location: false,
            locationAdvanced: false,
            when: false,
            whenAdvanced: false,
            respondTill: true,
            allowToAddOptions: true
        },
        defaultSettings: {
            hideParticipants: true,
            theme: 'human-hands',

        },
        options: {
            optionTypes: ['text_slider', 'divider', 'text'],
            showOptionTypeIcon: true,
            showDescription: true,
            showMaxParticipants: false,
        },
    }],
    [TemplateKey.preferenceNew, {
        general: {
            inheritFrom: TemplateKey.preference,
            show: false,
            useStepper: true

        },
    },],



    [TemplateKey.order, {
        general: {
            inheritFrom: TemplateKey.advanced,
            icon: 'shoping_cart',
            previewImage: '/poll-preview/order-food.webp',
            show: true,
            defaultOptions: 'order',
            useOptionsListPreview: false,

        },
        availableSettings: {
            chooseTheme: true,
            multiplePersons: false,
            kids: false,
            vegetariansForMultiplePersons: false,
            vegetarians: false,
            multiplePersonsAdvanced: false,
            kidsAdvanced: false,
            vegetariansForMultiplePersonsAdvanced: false,
            vegetariansAdvanced: false,
            location: false,
            locationAdvanced: false,
            when: false,
            whenAdvanced: false,
            respondTill: false,
            allowToAddOptions: false,

        }
        ,
        defaultSettings: {
            theme: 'pizza',
            allowToAddOptions: false,
            askForMultiplePersons: false,
            askForKids: false,
            askForPrivateComments: false
        },
        options: {
            optionTypes: ['text_textAndNumber'],
            showDescription: false,
            showMaxParticipants: false,
            showOptionTypeIcon: false,

        },
        responsesDashboard: {
            showChartBestTextOptions: false,
        }

    }],

    [TemplateKey.lunchGroup, {
        general: {
            inheritFrom: TemplateKey.advanced,
            previewImage: '/poll-preview/lunch-group.webp',
            show: false,
            useOptionsListPreview: true,
            defaultOptions: 'lunch'


        },
        availableSettings: {
            chooseTheme: true,
            multiplePersons: false,
            kids: false,
            vegetariansForMultiplePersons: false,
            vegetarians: false,
            multiplePersonsAdvanced: false,
            kidsAdvanced: false,
            vegetariansForMultiplePersonsAdvanced: false,
            vegetariansAdvanced: false,
            location: false,
            locationAdvanced: false,
            when: false,
            whenAdvanced: false,
            respondTill: false,
            allowToAddOptions: false,

        }
        ,
        defaultSettings: {
            theme: 'spaghetti',
            allowToAddOptions: false,
            askForMultiplePersons: false,
            askForKids: false,
            askForPrivateComments: false
        },
        options: {
            optionTypes: ['date_willJoin', 'text', 'text_text'],
            showDescription: false,
            showMaxParticipants: false,
            showOptionTypeIcon: false,

        },
        responsesDashboard: {
            showChartBestTextOptions: false,
        }

    }],

]);



