

export interface PollKeysData {
    ownerId: string;
    pollId: string;

};

export class PollKeysClass {
    private _pollKeysData: PollKeysData

    constructor(pollKeysData: PollKeysData) {
        this._pollKeysData = pollKeysData;
    }

    get pollKeyData(): PollKeysData {
        return this._pollKeysData
    }

    set pollKeyData(pollKeysData: PollKeysData) {
        this._pollKeysData = pollKeysData
    }

    get ownerId(): string {
        return this.pollKeyData.ownerId
    }

    set ownerId(ownerId: string) {
        this.pollKeyData.ownerId = ownerId
    }

    get pollId(): string {
        return this.pollKeyData.pollId
    }

    set pollId(pollId: string) {
        this.pollKeyData.pollId = pollId
    }

    isEqual(toCompareWith: PollKeysClass): boolean {
        return this.pollId === toCompareWith.pollId && this.ownerId === toCompareWith.ownerId
    }

}
