// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    adClient: 'ca-pub-XYZ',
    google_analytics_key: 'G-2KXJVSV326',
    googleAnalyticsId: "UA-203731106-2",
    appVersion: require('../../../../package.json').version + '-dev',
    production: false,
    isLocal: false,

    debug_log: true,
    ngxLoggerLevel: 1,
    ngxServerLoggerLevel: 5,

    brandName: 'Whocan',
    home_button_label: 'Whocan.dev',
    home_button_label_short: 'Whocan.dev',
    host: 'whocan.dev',
    host_url: 'https://www.whocan.dev',
    useEmulators: false,
    blog: {
        urlApiEn: 'https://www.googleapis.com/blogger/v3/blogs/3670687097891151220',
        urlApiDe: 'https://www.googleapis.com/blogger/v3/blogs/2202450060368335489',
        urlUiEn: "http://blog.whocan.org/",
        urlUiDe: "http://blog-de.whocan.org/",
        key: 'AIzaSyAiagCkki-2LDIlUMCLw3i_A_q4W9VOrbE'
    },

    firebase: {
        apiKey: 'AIzaSyBS-sKEbYMxF6rHY_LMCQZO_gd23hSKNF8',
        authDomain: 'www.whocan.dev',
        databaseURL: 'https://whoo-dev.firebaseio.com',
        projectId: 'whoo-dev',
        functionsUrl: 'https://us-central1-whoo-dev.cloudfunctions.net',
        storageBucket: 'whoo-dev.appspot.com',
        messagingSenderId: '911083528126',
        appId: '1:911083528126:web:7c85c7eb26b00956bf63ef',
        measurementId: 'G-EYYYHQ9PJG',
    },

};



/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
