import {
    Component,
    ElementRef,
    HostListener,
    ViewChild
} from '@angular/core';
import { ResponsiveService } from '../services/responsive.service';
import { NGXLogger as LoggerService } from "ngx-logger";


@Component({
    selector: 'sticky-bar',
    standalone: true,
    templateUrl: './sticky-bar.component.html',
    styleUrls: ['./sticky-bar.component.scss'],
})
export class StickyBarComponent {
    @ViewChild('stickyBars') stickyBars: ElementRef;
    @ViewChild('pageEnd') pageEnd: ElementRef;
    private prevScroll: number;
    constructor(
        public responsiveService: ResponsiveService,
        private logger: LoggerService,
    ) { }

    @HostListener('window:scroll', ['$event'])
    handleScroll() {
        if (this.stickyBars) {
            const barHeight = this.stickyBars.nativeElement.offsetHeight;
            if (!this.isScrollBottom && this.isScrollDirectionUp) {
                this.stickyBars.nativeElement.style.top = '0px';
            } else {
                this.stickyBars.nativeElement.style.top =
                    -1 * (barHeight + 1) + 'px';
            }
        }
    }

    /**
     * True if scroll direction is up, false other wise
     */
    private get isScrollDirectionUp(): boolean {
        let returnValue = false;
        const curScroll = window.scrollY;
        if (curScroll < this.prevScroll) {
            returnValue = true;
        } else {
        }
        this.prevScroll = curScroll;
        return returnValue;
    }

    /**
     * If you scroll on the iphon to the bottom it scolls autmatically a little bit up again.
     * in this case we dont want to show the bars
     */
    private get isScrollBottom(): boolean {
        if (
            this.pageEnd.nativeElement.offsetTop - 30 <
            window.innerHeight + window.scrollY
        ) {
            //       this.logger.log('isScrollBottom true', this.pageEnd.nativeElement.offsetTop, window.innerHeight + window.scrollY)
            return true;
        } else {
            //     this.logger.log('isScrollBottom false')
            return false;
        }
    }

}
