import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { AuthService } from '../../../user/auth.service';
import { HeaderService } from '../poll-header/header.service';
import { NavigationService } from '../../core/navigation.service';
import { CurrentPollService } from '../../core/current-poll.service';
import { TimePeriodClass } from 'whocan-lib';
import ical, { ICalCalendar } from 'ical-generator';
import { TemplateService } from '../../core/poll-templates/template.service';
import { environment } from '../../../../environments/environment';
import { PollService } from '../../core/poll.service';
import { EssErrorService } from 'ngx-essentia';
import { saveAs } from 'file-saver';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import { UserVoteService } from '../../features/vote/user-vote.service';
import { OptionsService } from '../options/options.service';


@Injectable({
    providedIn: 'root'
})

export class ICalService {
    constructor(
        private logger: LoggerService,
        private userVoteService: UserVoteService,
        private headerService: HeaderService,
        private optionsService: OptionsService,
        private navigationService: NavigationService,
        private authService: AuthService,
        private currentPollService: CurrentPollService,
        private pollService: PollService,
        private templateService: TemplateService,
        private errorService: EssErrorService,
        private googleAnayltics: GoogleAnalyticsService

    ) {
    }

    public downloadAllEvents() {
        this.googleAnayltics.emitSimpleEvent('ical_downlaod_all')
        const blob = new Blob([this.allEventsFromPoll], { type: 'text/calendar' });//https://stackoverflow.com/questions/51231882/javascript-in-mobile-safari-ios-wont-download-calendar-invite
        saveAs(blob, 'whocan.ics');
    }



    public downloadEventsVotesFor() {
        this.googleAnayltics.emitSimpleEvent('ical_downlaod_votes_for')
        const blob = new Blob([this.eventsVotedFor], { type: 'text/calendar' });
        saveAs(blob, 'whocan.ics');
    }

    private newCalendar() {
        return ical({ name: 'Whocan.org' })
    }

    public get hasPollDateEvents(): boolean {
        if (!this.pollService.pollIsLoaded()) { return false }
        if (this.templateService.getConfigAttribute('availableSettings', 'when') === true) {
            return true
        }
        let returnValue = false;
        this.optionsService.dateOptions.length > 0 ? returnValue = true : returnValue = false;
        return returnValue;

    }

    get allEventsFromPoll(): string {
        if (!this.pollService.pollIsLoaded()) { throw this.errorService.newError('poll not loaded') }
        const calendar = this.newCalendar()
        if (this.templateService.getConfigAttribute('availableSettings', 'when')) {
            this.logger.log('header time', this.headerService.headerClass.when)
            this.createEventFromTimePeriod(calendar, this.headerService.headerClass.when)
        }
        this.optionsService.dateOptions.forEach(dateOption => {
            this.createEventFromTimePeriod(calendar, dateOption.timePeriodClass)
        })
        return calendar.toString()
    }

    public get hasDateEventsVotedFor(): boolean {
        if (!this.pollService.pollIsLoaded()) { return false }
        if (this.templateService.getConfigAttribute('availableSettings', 'when') && this.userVoteService.userVote?.choiceInvitation !== 0) {
            return true
        }
        let returnValue = false;
        this.optionsService.dateOptions.forEach(dateOption => {
            if (this.userVoteService.userVote?.getChoiceIsYes(dateOption.index)) {
                returnValue = true;
            }
        })
        return returnValue;
    }

    get eventsVotedFor(): string {
        if (!this.pollService.pollIsLoaded()) { throw this.errorService.newError('poll not loaded') }
        const calendar = this.newCalendar()
        if (this.templateService.getConfigAttribute('availableSettings', 'when') && this.userVoteService.userVote.choiceInvitation !== 0) {
            this.createEventFromTimePeriod(calendar, this.headerService.headerClass.when)
        }
        this.optionsService.dateOptions.forEach(dateOption => {
            if (this.userVoteService.userVote?.getChoiceIsYes(dateOption.index)) {
                this.createEventFromTimePeriod(calendar, (dateOption.timePeriodClass))
            }
        })
        return calendar.toString()
    }

    private createEventFromTimePeriod(calendar: ICalCalendar, timePeriod: TimePeriodClass) {
        this.logger.log('createEventFromTimePeriod', timePeriod)
        if (timePeriod.isAllDay) {
            this.logger.log('createEventFromTimePeriodData Allday', timePeriod)
            calendar.createEvent({
                start: timePeriod.startDateTime,
                allDay: true,
                summary: this.getSummary(),
                description: this.getDescription(),
                location: this.headerService.headerClass.showLocation ? this.headerService.headerClass.location : '',
            })
        } else {
            calendar.createEvent({
                start: timePeriod.startDateTime,
                end: timePeriod.endDateTime,
                summary: this.getSummary(),
                description: this.getDescription(),
                location: this.headerService.headerClass.showLocation ? this.headerService.headerClass.location : '',
            })
        }
    }

    private getSummary(): string {
        return this.headerService.headerClass.pollTitle + ' (' + environment.brandName + ')'
    }

    private getDescription(): string {
        let returnValue = "";
        if (this.headerService.headerClass.pollDescription) {
            returnValue += this.headerService.headerClass.pollDescription + '\n\n';
        }
        if (this.currentPollService.isOwner) {
            returnValue += this.templateService.getLabel('iCal', 'ownerLine1') + '\n\n';
            returnValue += this.templateService.getLabel('iCal', 'ownerLine2') + '\n\n';
        } else {
            returnValue += this.templateService.getLabel('iCal', 'notOwnerLine1') + '\n\n';
            returnValue += this.templateService.getLabel('iCal', 'notOwnerLine2') + '\n\n';
        }
        returnValue = this.replaceVariables(returnValue);
        return returnValue;
    }

    private replaceVariables(input: string) {
        let output = this.replacePollOwner(input);
        output = this.replacePollTitle(output);
        output = this.replacePollUrl(output);
        output = this.replaceBrandName(output);
        output = this.replaceNewLine(output);
        return output;
    }

    private replaceNewLine(input: string) {
        return input.replace(/{NEW_LINE}/g, '\n');
    }
    private replaceBrandName(input: string) {
        return input.replace(/{BRAND_NAME}/g, environment.brandName);
    }
    private replacePollOwner(input: string) {
        return input.replace(/{POLL_OWNER}/g, this.headerService.headerClass.ownerName);
    }
    private replacePollTitle(input: string) {
        return input.replace(/{POLL_TITLE}/g, this.headerService.headerClass.pollTitle);
    }
    private replacePollUrl(input: string) {
        return input.replace(/{POLL_URL}/g, this.getUrl());
    }

    private getUrl() {
        let url: string;
        if (this.currentPollService.isOwner) {
            if (this.authService.isAnonymous) {
                url = this.navigationService.getUrlAssignAccessPollResponses()
            } else {
                url = this.navigationService.getUrlPollResponses();
            }
        } else {
            if (this.authService.isAnonymous) {
                url = this.navigationService.getUrlAssignAccessVote();
            }
            else {
                url = this.navigationService.getUrlVote();
            }
        }
        return url;
    }
}

