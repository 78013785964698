import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ResponsiveService } from '../../../../shared/services/responsive.service';

@Component({
    selector: 'testimonial-item',
    standalone: true,
    imports: [CommonModule, MatDividerModule],
    templateUrl: './testimonial-item.html',
    styleUrls: ['./testimonial-item.scss'],
})
export class TestemonialItem {
    @Input() name: string;
    @Input() imageUrl: string;
    @Input() text: string;
    @Input() titel: string;
    @Input() imageRight = false;

    constructor(public responsiveService: ResponsiveService) { }

    get imageLeftRight() {
        if (this.imageRight) {
            return 'image-right';
        }
        return 'image-left';
    }

    public async showImage() { }
}
