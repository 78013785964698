
export enum TemplateKey {
    schedule = 'schedule',
    scheduleNew = 'scheduleNew',
    doodleAlternative = 'doodleAlternative',
    doodleAlternativeNew = 'doodleAlternativeNew',
    participation = 'participation',
    participationNew = 'participationNew',
    invitation = 'invitation',
    birthdayInvitation = 'birthdayInvitation',
    duties = 'duties',
    dutiesNew = 'dutiesNew',
    dutiesDate = 'dutiesDate',
    dutiesDateNew = 'dutiesDateNew',
    potluck = 'potluck',
    potluckNew = 'potluckNew',
    preference = 'preference',
    preferenceNew = 'preferenceNew',
    advanced = 'advanced',
    advancedNew = 'advancedNew',
    order = 'order',
    lunchGroup = 'lunchGroup',
}
