import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NavigationService } from '../../whoo/core/navigation.service';
import { ResponsiveService } from '../services/responsive.service';

@Component({
    selector: 'kp-alert',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatButtonModule],
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    @Input() message: string; /** Additional content of Alert	 */
    @Input() description: string; /** Content of Alert	 */
    @Input() closeable: boolean; /** Whether Alert can be closed	 */
    @Input() type =
        'info'; /** Type of Alert styles; Type:  success' | 'info' | 'warning' | 'error', default is 'infor' */
    @Input() goToLink: string;
    @Input() goToText: string;
    @Output() onClose =
        new EventEmitter<void>() /** Callback when Alert is closed */;
    public isClosed = false;

    constructor(
        public responsiveService: ResponsiveService,
        private navigationService: NavigationService
    ) { }
    public close(): void {
        this.isClosed = true;
    }
    public goToRoute(): void {
        this.navigationService.navigate(this.goToLink);
    }
}
