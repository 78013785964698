export enum ChoiceEnum {
    yes = 'YES',
    no = 'NO',
    mayBe = 'MAYBE',
    notSelected = 'NOT_SELECTED'
}
export interface SecondaryAnswerData { count?: number, text?: string }

export interface ChoiceData {
    choiceEnum: ChoiceEnum;
    count?: number; /**  for the secondary answer - how many of the  something -   */
    text?: string /** text answer */
    secondaryAnswerArray?: SecondaryAnswerData[]
    adultsCount?: number; /** @deprictated: can be handled using the number answer */
    kidsCount?: number; /** @deprictated: can be handled using the number answer */
    adultVegetariansCount?: number; /** @deprictated: can be handled using the number answer */
    kidVegetariansCount?: number; /** @deprictated: can be handled using the number answer */
    comment?: string; /** @todo make it a seperate objectArray - a user may add various comments, it also needs a date with it*/
}

export class ChoiceClass {
    private _choiceEnum: ChoiceEnum /**  @depricated */
    private _choiceData: ChoiceData
    constructor(choice?: ChoiceEnum | ChoiceData) {
        if (ChoiceClass.isOfTypeChoiceEnum(choice)) {
            this._choiceEnum = <ChoiceEnum>choice
        } else if (ChoiceClass.isOfTypeChoiceData(choice)) {
            this._choiceData = <ChoiceData>choice
        } else {
            this._choiceData = { choiceEnum: ChoiceEnum.notSelected }; // so the default is typeChoiceData
            this._choiceEnum = undefined;
        }
        if (this._choiceData && !this._choiceData.secondaryAnswerArray) {
            this._choiceData.secondaryAnswerArray = [];
            this._choiceData.secondaryAnswerArray.push({ text: "" })
        };
    }

    /** remove empty item in secondaryAnsdwerArray */
    public cleanUpSecondaryAnswerArray() {
        const returnValue = []
        if (!this._choiceData) return
        if (!this._choiceData.secondaryAnswerArray) return
        if (this._choiceData.secondaryAnswerArray.length > 0) {
            this._choiceData.secondaryAnswerArray.forEach(answer => {
                if (answer.text || answer.count != null) {
                    returnValue.push(answer)
                }
            })
        }
    }

    public static isOfTypeChoiceData(choice: ChoiceEnum | ChoiceData | undefined): boolean {
        return choice !== undefined && typeof choice === 'object'
    }

    public static isOfTypeChoiceEnum(choice: ChoiceEnum | ChoiceData | undefined): boolean {
        return choice !== undefined && typeof choice === 'string'
    }

    public get isOfTypeChoiceData(): boolean {
        return this._choiceData !== undefined
    }

    public get isOfTypeChoiceEnum(): boolean {
        return this._choiceEnum !== undefined
    }

    get choiceEnum(): ChoiceEnum {
        if (this.isOfTypeChoiceData) return this._choiceData.choiceEnum
        if (this.isOfTypeChoiceEnum) return this._choiceEnum
        throw (new Error('get choiceEnum'))
    }

    set choiceEnum(value: ChoiceEnum) {
        if (this.isOfTypeChoiceData) this._choiceData.choiceEnum = value
        if (this.isOfTypeChoiceEnum) this._choiceEnum = value
    }
    get choiceData(): ChoiceData {
        return this._choiceData
    }

    set choiceData(choiceData: ChoiceData) {
        if (!this.isOfTypeChoiceData) throw (new Error('set choiceData'))
        this.choiceData = choiceData
    }


    get secondaryAnswerArray(): SecondaryAnswerData[] {
        return this._choiceData?.secondaryAnswerArray
    }


    get adultsCount(): number {
        if (this.isOfTypeChoiceData && this.choiceData.adultsCount) return this.choiceData.adultsCount
        return 0;
    }

    set adultsCount(value: number) {
        if (!this.isOfTypeChoiceData) throw (new Error('set adultsCount'))
        this.choiceData.adultsCount = value;
    }


    get kidsCount(): number {
        if (this.isOfTypeChoiceData && this.choiceData.kidsCount) return this.choiceData.kidsCount
        return 0;
    }

    set kidsCount(value: number) {
        if (!this.isOfTypeChoiceData) throw (new Error('set kidsCount'))
        this.choiceData.kidsCount = value;
    }


    get adultVegetariansCount(): number {
        if (this.isOfTypeChoiceData && this.choiceData.adultVegetariansCount) return this.choiceData.adultVegetariansCount
        return 0;
    }

    set adultVegetariansCount(value: number) {
        if (!this.isOfTypeChoiceData) throw (new Error('set adultVegetariansCount'))
        this.choiceData.adultVegetariansCount = value;
    }


    get kidVegetariansCount(): number {
        if (this.isOfTypeChoiceData && this.choiceData.kidVegetariansCount) return this.choiceData.kidVegetariansCount
        return 0;
    }

    set kidVegetariansCount(value: number) {
        if (!this.isOfTypeChoiceData) throw (new Error('set kidVegetariansCount'))
        this.choiceData.kidVegetariansCount = value;
    }

    public isEnumEqual(choiceEnum: ChoiceEnum): boolean {
        return ChoiceClass.isEnumEqual(this.choiceEnum, choiceEnum)
    }


    get comment(): string {
        if (this.isOfTypeChoiceData && this.choiceData.comment) return this.choiceData.comment;
        return '';
    }

    set comment(value: string) {
        if (!this.isOfTypeChoiceData) throw new Error('set comment');
        this.choiceData.comment = value;
    }

    static isEnumEqual(choice1: ChoiceEnum | ChoiceData, choice2Enum: ChoiceEnum): boolean { // todo: implement a PollChoice class
        const choice1Enum = new ChoiceClass(choice1).choiceEnum
        if (ChoiceClass.isNo(choice1Enum) && ChoiceClass.isNo(choice2Enum)) {
            return true
        }
        if (choice1Enum === choice2Enum) {
            return true
        }
        return false
    }


    get isNo(): boolean {
        return ChoiceClass.isNo(this.choiceEnum)
    }

    public static isNo(pollChoice: ChoiceEnum) {
        if (pollChoice === ChoiceEnum.yes || pollChoice === ChoiceEnum.mayBe) {
            return false
        }
        return true
    }

    get isYes(): boolean {
        if (this.choiceEnum === ChoiceEnum.yes) return true;
        return false
    }

    public static isYes(pollChoice: ChoiceEnum) {
        if (pollChoice === ChoiceEnum.yes) {
            return true
        }
        return false
    }

    get isMaybe(): boolean {
        if (this.choiceEnum === ChoiceEnum.mayBe) return true;
        return false
    }

}
