import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { environment } from '../../../environments/environment';
import { AuthService } from '../../user/auth.service';
import { Subscription } from 'rxjs';
declare let gtag: Function;


@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService implements OnDestroy {
    private localStorageKey = "whoo:ga:consent";
    private subscriptionAuthUser: Subscription;
    private _showCookieConsent: boolean = false;
    constructor(
        private logger: LoggerService,
        private authService: AuthService,
        @Inject(LOCALE_ID) public locale: string,

    ) { }
    ngOnDestroy(): void {
        this.subscriptionAuthUser.unsubscribe()
    }

    get showCookieConsent(): boolean {
        return this._showCookieConsent
    }



    get loggedIn(): string {
        if (this.authService.isLoggedIn) {
            return 'logged_in'
        } else {
            return 'not_logged_in'
        }
    }

    get anonymous(): string {
        if (this.authService.isLoggedIn) {
            if (this.authService.isAnonymous) {
                return 'anonymous'
            }
            else {
                return 'not_anonymous'
            }
        } else {
            return 'not_logged_in'
        }
    }

    public emitSimpleEvent(eventName: string) {
        gtag('event', eventName, {
            logged_in: this.loggedIn,
            anonymous: this.anonymous,
            language_code: this.locale,
        })
    }

    public emitVote(template: string, theme: string) {
        gtag('event', 'vote', {
            logged_in: this.loggedIn,
            anonymous: this.anonymous,
            language_code: this.locale,
            poll_template: template,
            poll_theme: theme
        })
    }

    public emitPollCreated(template: string, theme: string) {
        gtag('event', 'poll_created', {
            logged_in: this.loggedIn,
            anonymous: this.anonymous,
            language_code: this.locale,
            poll_template: template,
            poll_theme: theme
        })
    }

    public emitError(errorDescription: string, fatal: boolean = false) {
        gtag('event', 'exception', {
            description: errorDescription,
            'fatal': fatal
        });
    }

    public emitPageView(pageTitle: string, pageLocation: string) {
        this.logger.log('emitPageView', pageTitle, pageLocation)
        gtag('event', 'page_view', {
            logged_in: this.loggedIn,
            anonymous: this.anonymous,
            language_code: this.locale,
            page_title: pageTitle,
            page_location: pageLocation,

        });
    }

    public initializeAndGetConsent(): void {
        if (!environment.isLocal) {
            this.logger.log('initializeAndGetConsent');
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
            script.defer = true; // Load the script asynchronously
            // gtag('consent', 'default', { 'analytics_storage': 'denied' }) - this will make sure we don't send any data to google before the user accepted it (in case he has legally to be to be asked)
            document.head.appendChild(script);
            gtag('config', environment.google_analytics_key), {
                'send_page_view': false, // we sent the page-views manually see: https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
            };
            gtag('set', {
                language_code: this.locale // gtag('set' )  seems actually not to work
            })
            this.subscribeToSetUserInformation()
            setTimeout(() => { // to simulate the waiting for the reponse from the consent dialog-box
                this.logger.log('checkForConsent wait');
                this.checkForConsent()
            }, 2000);
        }

    }

    private subscribeToSetUserInformation() {
        //  this.setUserInformation();
        if (this.subscriptionAuthUser) this.subscriptionAuthUser.unsubscribe
        this.subscriptionAuthUser = this.authService.afAuthUser$.subscribe((authUser) => {
            this.setUserInformation()
        })
    }

    private setUserInformation() {
        this.logger.log('setUserInformation')
        if (this.authService.isLoggedIn) {
            gtag('config', environment.google_analytics_key), {
                'user_id': this.authService.uid
            };
        }
        gtag('set', { // gtag('set' )  seems actually not to work
            logged_in: this.loggedIn,
            anonymous: this.anonymous,
        });
    }

    private async checkForConsent() {
        if (!this.previousConsent) {
            this.logger.log('!this.previousConsent)')
            if (await this.getConsentNeededByRegion()) {
                this.logger.log('this.getConsentNeededByRegion')
                this._showCookieConsent = true;
            }
        }
    }


    get previousConsent(): boolean {
        const localStorageResult = window.localStorage.getItem(this.localStorageKey)
        if (localStorageResult === 'granted') {
            return true;
        } else {
            return false;
        }
    }

    private async getConsentNeededByRegion(): Promise<boolean> {
        // @todo get this by region
        return true
    }

    public setConsentGranted() {
        this.emitSimpleEvent('consent_granted')
        this._showCookieConsent = false
        window.localStorage.setItem(this.localStorageKey, 'granted')
    }


    public setConsentDenied() {
        this._showCookieConsent = false
        this.emitSimpleEvent('consent_denied')
        window.localStorage.setItem(this.localStorageKey, 'denied')
        this.disableGoogleAnalytics()
    }


    private disableGoogleAnalytics() {
        this.logger.log('disableGoogleAnalytics')
        gtag('consent', 'default', {
            'analytics_storage': 'denied',
            'wait_for_update': 500
        })
        this.deleteCookies()
    }

    private deleteCookies() {
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

    }
}
