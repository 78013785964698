import {
    Component,
    OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../services/responsive.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppShellService } from '../../app-shell/nav/nav.service';
import { TranslateMigratedPipe } from '../translation/trans-migrated.pipe';

@Component({
    selector: 'feedback-button',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateMigratedPipe
    ],
    templateUrl: './feedback-button.component.html',
    styleUrls: ['./feedback-button.component.scss'],
})
export class FeedbackButtonComponent implements OnInit {
    dialogRef: MatDialogRef<unknown>;

    constructor(
        public responsiveService: ResponsiveService,
        private logger: LoggerService,
        public dialog: MatDialog,
        private dialogService: EssDialogService,
        public appShellService: AppShellService

    ) { }

    ngOnInit() {
    }

    public onFeedbackClick(): void {
        this.dialogRef?.close(); // make sure we don't have multiple dialog open
        import('./feedback-dialog/feedback-dialog').then(
            (module) => {
                this.dialogRef = this.dialogService.open(
                    module.FeedbackDialog,
                    {}
                );
            }
        );
    }
}
