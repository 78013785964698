import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NavigationService } from '../../whoo/core/navigation.service';
import { ResponsiveService } from '../services/responsive.service';
import { MatIconModule } from '@angular/material/icon';
import { BannerService } from './banner.service';

@Component({
    selector: 'ess-banner',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
    constructor(
        public responsive: ResponsiveService,
        private navigationService: NavigationService,
        public bannerService: BannerService
    ) { }

    get showIcon(): boolean {
        return this.bannerService.icon ? true : false;
    }

    get showLeftButton(): boolean {
        return this.bannerService.leftButtonLabel ? true : false
    }

    public handleLefttButtonClick(): void {
        // todo: let the service know to hide the current banner message
        this.bannerService.handleLeftButtonClick()
    }
    public handleRightButtonClick(): void {
        // todo: let the service know to hide the current banner message
        this.bannerService.handleRightButtonClick()
    }
}
