<ng-container *ngIf="translationLoaded else SPINNER">
    <landing-page-section-top [templateKey]="templateKey" imageSource="assets/images/lady-4-with-background.png">
    </landing-page-section-top>

    <div class="whoo-why-whocan-container">
        <why-whocan [templateKey]="templateKey"></why-whocan>
    </div>

    <div class="whoo-whocan-advantages-container">
        <whocan-advantages [templateKey]="templateKey"></whocan-advantages>
    </div>

    <div class="whoo-testimonial-standalone-container">
        <testimonial-standalone [templateKey]="templateKey" name="Alexander"
            imageUrl="assets/images/profiles/new/alexander.png">
        </testimonial-standalone>
    </div>

    <div class="whoo-how-it-works-section-container">
        <how-it-works [templateKey]="templateKey" [showCreatePollButton]="true"
            [bigScreenImages]="howItWorksBigScreenImages" [smallScreenImages]="howItWorksSmallScreenImages">
        </how-it-works>
    </div>

    <div class="whoo-section-container whoo-poll-templates-container">
        <div class="whoo-poll-templates">
            <h2>
                {{templateKey + '.landingPagePollTemplates.headline'| transPage }}
            </h2>
            <poll-templates [hideTemplates]="hideTemplates" [openDialog]="true" [singleColor]="true" appearance="image">
            </poll-templates>
        </div>
    </div>



    <div class="whoo-founder-statement-container">
        <founder-statement headline="{{templateKey + '.landingPageFounderStatement.headline'| transPage }}"
            name='Paul & Kai' imgSrc='assets/images/profiles/paul-and-kai.jpeg'
            paragraph1="{{templateKey + '.landingPageFounderStatement.paragraph1'| transPage }}"
            paragraph2="{{ templateKey + '.landingPageFounderStatement.paragraph2'| transPage }}">
        </founder-statement>
    </div>




    <div class="whoo-section-container whoo-testimonials">
        <h2> {{templateKey + '.landingPageTestimonials.headline'| transPage }} </h2>
        <testimonial-item [imageRight]="true" name="Lars" imageUrl="assets/images/profiles/new/lars.webp"
            text="{{templateKey + '.landingPageTestimonials.text-1'| transPage }}"
            titel="{{templateKey + '.landingPageTestimonials.title-1'| transPage }}">
        </testimonial-item>
        <testimonial-item [imageRight]="false" name="Maria" imageUrl="assets/images/profiles/new/maria.webp"
            text="{{templateKey + '.landingPageTestimonials.text-2'| transPage }}"
            titel="{{templateKey + '.landingPageTestimonials.title-2'| transPage }}">
        </testimonial-item>
        <testimonial-item [imageRight]="true" name="Michael" imageUrl="assets/images/profiles/new/michael.webp"
            text="{{templateKey + '.landingPageTestimonials.text-3'| transPage }}"
            titel="{{templateKey + '.landingPageTestimonials.title-3'| transPage }}">
        </testimonial-item>
        <testimonial-item [imageRight]="false" name="Kora" imageUrl="assets/images/profiles/new/kora.webp"
            text="{{templateKey + '.landingPageTestimonials.text-4'| transPage }}"
            titel="{{templateKey + '.landingPageTestimonials.title-4'| transPage }}">
        </testimonial-item>
        <testimonial-item [imageRight]="true" name="Magarita" imageUrl="assets/images/profiles/new/magarita.webp"
            text="{{templateKey + '.landingPageTestimonials.text-5'| transPage }}"
            titel="{{templateKey + '.landingPageTestimonials.title-5'| transPage }}">
        </testimonial-item>
    </div>
</ng-container>

<ng-template #SPINNER>
    <ess-spinner></ess-spinner>
</ng-template>