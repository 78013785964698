import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxEssentiaModule } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { TranslationGeneralService } from '../../../shared/translation/translation.general.service';
import { UIMesageService } from '../../../shared/services/ui-message.service';
import { AuthService } from '../../../user/auth.service';
import { CurrentPollService } from '../../core/current-poll.service';
import { HeaderService } from '../poll-header/header.service';
import { NavigationService } from '../../core/navigation.service';
import { TemplateService } from '../../core/poll-templates/template.service';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import { TranslateMigratedPipe } from '../../../shared/translation/trans-migrated.pipe';

@Component({
    selector: 'share-poll',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgxEssentiaModule,
        ClipboardModule,
        TranslateMigratedPipe
    ],
    templateUrl: './share-poll.component.html',
    styleUrls: ['./share-poll.component.scss'],
})
export class SharePollComponent implements OnInit {
    @Input() showHeadline = true;
    @Input() copyToClipboardOnInit = true;
    public pollTitle = '';
    constructor(
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private templateService: TemplateService,
        public headerService: HeaderService,
        private navigationService: NavigationService,
        public currentPollService: CurrentPollService,
        private authService: AuthService,
        private trans: TranslationGeneralService,
        private clipboard: Clipboard,
        private googleAnayltics: GoogleAnalyticsService,
    ) { }

    ngOnInit(): void {
        this.logger.log('ngOnInit: ');
        this.googleAnayltics.emitSimpleEvent('share_poll')
        if (this.copyToClipboardOnInit) this.copyToClipboard()
    }



    get pollUrl(): string {
        if (this.currentPollService.isInitialized) {
            return this.navigationService.getUrlSharePoll();
        }
        return '';
    }

    public copyToClipboard() {
        this.logger.log('sendUiMessage');
        this.clipboard.copy(this.pollUrl);
        this.uiMessage.success(this.trans.translate('poll.urlCopied'));
    }

    public getWhatsappHref() {
        return 'https://wa.me/?text=' + this.message;
    }

    public getEmailHref() {
        let result: string;
        result =
            'mailto:?subject=' +
            this.templateService.getLabel('share', 'subject') +
            ' ' +
            this.title;
        result = result + '&body=' + this.message;
        return result;
    }

    get title() {
        return this.headerService.headerClass?.pollTitle;
    }

    get message() {
        let result: string;
        const br = '%0D%0A%0D%0A';
        result = this.templateService.getLabel('share', 'salutation');
        result = result + br + this.templateService.getLabel('share', 'messageLine1'); + ' ' + this.title;

        result =
            result + br + this.templateService.getLabel('share', 'messageLine2') + br + this.pollUrl;
        result = result + br + this.templateService.getLabel('share', 'greeting');
        if (
            this.authService.displayName &&
            this.authService.displayName !== ''
        ) {
            result = result + br + this.authService.displayName;
        }
        return result;
    }
}
