import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


import { EssDialogService } from 'ngx-essentia';

import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { NavigationService } from '../../../../whoo/core/navigation.service';
import { TemplateKey } from '../../../../whoo/core/poll-templates/template.configuration';
import { NGXLogger as LoggerService } from "ngx-logger";

@Component({
    selector: 'create-poll-button',
    templateUrl: './create-poll.button.html',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        MatButtonModule,
        FormsModule,
    ],
    styleUrls: ['./create-poll.button.scss'],
})
export class CreatePollButton {
    @Input() titleFieldLabel = 'Title of your event';
    @Input() titleFieldLabelShort: string;
    @Input() showField: boolean = true;
    @Input() buttonLabel = 'Create Poll';

    @Input() buttonLabelShort: string
    @Input() pollTemplateKey: string;
    pollTitle = '';
    private dialogRef: MatDialogRef<unknown>;

    constructor(
        public responsiveService: ResponsiveService,
        private navigationService: NavigationService,
        private dialogService: EssDialogService,
        private logger: LoggerService,
    ) { }

    get fieldLabel() {
        if (this.responsiveService.isScreenBig()) {
            return this.titleFieldLabel;
        } else {
            if (this.titleFieldLabelShort) {
                return this.titleFieldLabelShort;
            }
            {
                return this.titleFieldLabel;
            }
        }
    }

    get buttonLabelResponsive() {
        if (this.responsiveService.isScreenBig()) {
            return this.buttonLabel;
        } else {
            if (this.buttonLabelShort) {
                return this.buttonLabelShort;
            }
            {
                return this.buttonLabel;
            }
        }
    }

    createPoll() {
        this.logger.log('createPoll')
        if (this.pollTemplateKey && this.pollTemplateKey !== TemplateKey.advanced) {
            this.navigationService.navigateCreatePoll(
                this.pollTitle,
                this.pollTemplateKey
            );
        } else {
            import(
                '../../../../whoo/shared/poll/create-poll-dialog/create-poll-dialog'
            ).then((module) => {
                this.dialogRef?.close(); // make sure we don't have multiple dialog open
                this.dialogRef = this.dialogService.open(
                    module.CreatePollDialog,
                    {
                        pollTitle: this.pollTitle,
                    },
                );
            });
        }
    }
}
