<div class="component-container">
    <div class="copy-link-container">
        <mat-card>
            <div class="card-title"> {{trans.translate('getPollAccess.dialogHeadline2')}}</div>
            <div class="copy-link-container">
                <mat-form-field class="field">
                    <input matInput [(ngModel)]="pollUrlForField" [disabled]="true">
                    <button matSuffix mat-icon-button matTooltip="{{trans.translate('getPollAccess.copyLinkTooltip')}}"
                        (click)="handleClickCopyToClipboard()">
                        <mat-icon>link</mat-icon>
                    </button>
                </mat-form-field>

            </div>
            <div class="dont-share">{{trans.translate('getPollAccess.dontShare')}}</div>
        </mat-card>

    </div>


    <div class="whoo-centered-container-fit-content or-devider"> -- {{trans.translate('getPollAccess.or')}} --</div>

    <div class="sign-up-container">

        <button mat-raised-button color="warn" (click)="handleClickSignUp()">
            {{trans.translate('getPollAccess.signUpButton')}}</button>

    </div>

</div>