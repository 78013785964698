import { HrefLangMapping } from "../shared/services/route-context.service";

export const blogAlternateHrefLangs: HrefLangMapping[] =
    [{
        en: {
            href: "2023/02/the-best-free-doodle-alternatives.html"
        },
        de: {
            href: "2023/02/die-besten-kostenlosen-doodle-alternativen.html"
        },
        it: {
            href: "le-migliori-alternative-gratuite-a-doodle"
        },
        fr: {
            href: "les-meilleures-alternatives-gratuites-a-doodle"
        },
        pt: {
            href: "as-melhores-alternativas-gratuitas-de-doodle"
        },
        es: {
            href: "doodle-calendly-comparacion"
        },
    },
    {
        en: {
            href: "2023/02/what-is-a-potluck.html"
        },
        de: {
            href: "2023/02/was-ist-ein-potluck.html"
        },
        it: {
            href: "che-cose-un-potluck"
        },
        fr: {
            href: "que-est-ce-que-un-potluck"
        },
        pt: {
            href: "jantar-potluck-festas-byob-op-que-sao-elas"
        },
        es: {
            href: "que-es-una-cena-potluck"
        },
    },
    {
        en: {
            href: "2023/02/free-doodle-alternative.html"
        },
        de: {
            href: "2023/02/kostenlose-doodle-alternative.html"
        },
        it: {
            href: "doodle-alternativa-gratuita"
        },
        fr: {
            href: "alternative-doodle-gratuit"
        },
        pt: {
            href: "alternativa-ao-doodle-gratuito"
        },
        es: {
            href: "alternativa-a-doodle"
        },
    },
    {
        en: {
            href: "2023/02/doodle-calendly-comparison.html"
        },
        de: {
            href: "2023/02/doodle-calendly-vergleich.html"
        },
        it: {
            href: "doodle-calendly-confronto"
        },
        fr: {
            href: "doodle-calendly-comparaison"
        },
        pt: {
            href: "doodle-calendly-comparando"
        },
        es: {
            href: "doodle-calendly-comparacion"
        },
    },
    {
        en: {
            href: "2023/02/doodle-cally-comparison.html"
        },
        de: {
            href: "2023/02/doodle-cally-vergleich.html"
        },
        it: {
            href: "doodle-cally-confronto"
        },
        fr: {
            href: "doodle-cally-comparaison"
        },
        pt: {
            href: "doodle-cally-comparando"
        },
        es: {
            href: "doodle-cally-comparacion"
        },
    },
    {
        en: {
            href: "2023/02/create-a-poll-with-whocan.html"
        },
        de: {
            href: "2023/02/umfrage-mit-whocan-erstellen.html"
        },
        it: {
            href: "creare-un-sondaggio-con-whocan"
        },
        fr: {
            href: "comment-creer-un-sondage-avec-whocan"
        },
        pt: {
            href: "criar-uma-sondagem-com-whocan"
        },
        es: {
            href: "crear-encuesta-con-whocan"
        },
    },
    {
        en: {
            href: "2023/02/poll-types.html"
        },
        de: {
            href: "2023/02/umfrage-typen.html"
        },
        it: {
            href: "tipi-sondaggio"
        },
        fr: {
            href: "whocan-types-de-sondages"
        },
        pt: {
            href: "diferentes-tipos-de-sondagens"
        },
        es: {
            href: "whocan-tipos-de-encuestas"
        },
    },

    {
        en: {
            href: "2023/02/how-to-find-the-best-time-for-a-meeting.html"
        },
        de: {
            href: "2023/02/wie-finde-ich-den-besten-termin.html"
        },
        it: {
            href: "come-trovare-orario-migliore-per-riunion"
        },
        fr: {
            href: "trouver-le-meilleur-moment-pour-une-reunion"
        },
        pt: {
            href: "como-encontrar-o-melhor-momento-para-uma-reuniao"
        },
        es: {
            href: "como-encontrar-el-mejor-tiempo-para-una-reunion"
        },
    },
    {
        en: {
            href: "2023/06/what-is-online-invitation.html"
        },
        de: {
            href: "2023/06/was-ist-eine-online-einladung.html"
        }
    },
    {
        en: {
            href: "2023/06/possible-send-invitations-via-whatsapp.html"
        },
        de: {
            href: "2023/06/einladungen-per-whatsapp-verschicken.html"
        }
    }

        ,
    {
        en: {
            href: "2023/08/send-invitations-for-children-birthday.html"
        },
        de: {
            href: "2023/03/einladungen-zum-kinder-geburtstag.html"
        }
    }

        ,
    {
        en: {
            href: "2023/08/how-to-write-birthday-invitation.html"
        },
        de: {
            href: "2023/08/wie-schreibt-man-eine-geburtstagseinladung.html"
        }

    }
        ,
    {
        en: {
            href: "2023/09/what-is-rsvp.html"
        },
        de: {
            href: "2023/09/was-ist-rsvp.html"
        }

    },
    {
        en: {
            href: "2023/09/best-apps-for-organizing-events.html"
        },
        de: {
            href: "2023/09/apps-zur-organisation-von-veranstaltungen-rsvpify-eventbrite-eventkingdom.html"
        }

    },
    {
        en: {
            href: "2023/11/whocan-20.html"
        },
        de: {
            href: "2023/11/whocan-20.html"
        }

    },
    {
        en: {
            href: "2023/12/Peronlized-Themes.html"
        },
        de: {
            href: "2023/12/Personalisierte-Themes.html"
        }

    }

    ]
