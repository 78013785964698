import { Injectable } from "@angular/core";
import { NGXLogger as LoggerService } from "ngx-logger";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { NavigationService } from "../../core/navigation.service";

@Injectable({
    providedIn: 'root'
})

export class ContextActionBarService {
    private clickEditSubject = new Subject<void>();
    private clickAddSubject = new Subject<string>();

    constructor(
        private logger: LoggerService,
        public navigationService: NavigationService,

    ) {
    }

    get editObservable(): Observable<void> {
        return this.clickEditSubject.asObservable();
    }

    get addOptionObservable(): Observable<string> {
        return this.clickAddSubject.asObservable();
    }

    async handleClickEdit() {
        this.logger.log('handleClickEdit');
        this.clickEditSubject.next(null);
    }

    async handleClickAddOption(optionType: string) {
        this.logger.log('handleClickAddOption', optionType);
        this.clickAddSubject.next(optionType);
    }

}


