<div [ngClass]="responsiveService.getBigSmall()">
    <div class="component-container">
        <h3 *ngIf="showHeadline">{{ 'invite-participants-using-one-of-the-below-channels' | transMigrated}}</h3>
        <div class="flex-container">
            <a href="{{getWhatsappHref()}}" *ngIf="responsiveService.isScreenSmall()" class="channel">
                <button mat-button>
                    <img src="assets/images/whatsapp_icon.png" height="22" class="whatsapp-icon">
                    <div class="button-label">
                        WhatsApp
                    </div>
                </button>
            </a>
            <a href="{{getEmailHref()}}" class="channel">
                <button mat-button>
                    <div>
                        <mat-icon>mail_outline</mat-icon>
                    </div>
                    <div class="button-label">{{ 'send-e-mail' | transMigrated}}</div>
                </button>
            </a>
            <button mat-button matTooltip="{{ 'copy-link-to-clipboard'  | transMigrated}}" (click)="copyToClipboard()"
                class="channel">
                <div> <mat-icon>link</mat-icon></div>
                <div class="button-label">
                    {{ 'copy-link' | transMigrated}}
                </div>
            </button>
        </div>
    </div>
</div>