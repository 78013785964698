
import { Component, Input } from '@angular/core';

@Component({
    selector: 'top-background',
    templateUrl: './top-background.component.html',
    standalone: true,
    imports: [
    ],
    styleUrls: ['./top-background.component.scss'],
})
export class TopBackgroundComponent {

    @Input() backgroundImageUrl: string;

    constructor(
    ) { }

}
