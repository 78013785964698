<div [ngClass]="imageLeftRight">


    <div class="box-container">
        <div class="box">

            <span [ngClass]="imageLeftRight">
                <img src="{{imageUrl}}" alt="{{name}}" class="circle" width="60" height="60" loading="lazy">
                <p class="text">

                    <span class="quote">»</span>{{text}}<span class="quote">«</span>
                </p>
                <div class="name-and-title">
                    <div class="name">{{name}}</div>
                    <div class="titel">{{titel}}</div>
                </div>
            </span>

        </div>
    </div>
</div>
