import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { OptionData, OptionListData } from 'whocan-lib';
import { AuthService } from '../../../user/auth.service';
import { EssErrorService } from 'ngx-essentia';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { PathService } from '../../core/path.service';
import { OptionClass, OptionList } from 'whocan-lib';
import firebase from 'firebase/compat/app';

@Injectable({
    providedIn: 'root'
})

export class OptionsService {
    private _optionList: OptionList
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        private logger: LoggerService,
        private authService: AuthService,
        private errorService: EssErrorService,
        private afd: AngularFireDatabase,
        private pathService: PathService,

    ) {
        this._optionList = new OptionList([])
    }

    public async load() {
        this.isLoading$.next(true);
        let optionsData: OptionData[] | OptionListData = await await (firstValueFrom(this.getObservable()))
        this._optionList = new OptionList(optionsData)
        this.logger.log('load optionlist: ', this._optionList)
        this.isLoading$.next(false);
    }



    public uninitialize() {
        this._optionList.uninitialize()
        this._optionList = new OptionList([])
    }

    public get optionList(): OptionList {
        return this._optionList
    }

    public set optionList(value: OptionList) {
        this._optionList = value
    }

    getObservable(ownerId?: string, pollId?: string): Observable<OptionData[]> {
        this.logger.log('optionsObservable');
        const ref: AngularFireObject<(OptionData[])> = this.afd.object(this.pathService.getOptionsPath(ownerId, pollId));
        return ref.valueChanges().pipe(
            map(data => {
                this.logger.log('obeservable: ', data);
                return data
            })
        );
    }

    /**
     * The one and only method to persist an option.
     * Updates also the optionList with resetLastSaveForOneOption
     * @param optionClass
     */
    public persistOption(optionClass: OptionClass) {
        const option = optionClass.optionData
        this.logger.log('persistOption', option);
        if (!option) {
            throw this.errorService.newError('persistOption: option undefined');
        }
        const index = option.index;
        this.logger.log('persist: ', option, index);
        if (!option.createdAt) {
            option.createdAt = firebase.database.ServerValue.TIMESTAMP;
        }
        option.updatedAt = firebase.database.ServerValue.TIMESTAMP;;
        // we don't want to persist the "Checked" attribute so we copy the option and remove the checked attribute
        const optionCopy = { ...option };
        optionCopy.checked = null;
        let path: string;
        if (this._optionList.isLegacyData) {
            path = `${this.pathService.getOptionsPath()}/${index}`
        } else {
            path = `${this.pathService.getOptionsArrayPath()}/${index}`
        }
        const ref: AngularFireObject<OptionData> = this.afd.object(path);
        ref.update(optionCopy);
        this.optionList.resetLastSaveForOneOption(optionClass)
    }

    /**
     * The one and only place to perist the sortMap of the optionList
     * Only for non legacy data
     * Updates also the sortList with resetSortMapLastSave
     *
     */
    public persistSortMap(force: boolean = false) {
        if (force || this.optionList.sortMapHasChangedSinceLastSave) {
            if (!this._optionList.isLegacyData) {
                this.logger.log('Persist sortmap')
                const ref: AngularFireObject<number[]> = this.afd.object(`${this.pathService.getOptionsSortMapPath()}`);
                ref.set(this._optionList.sortMap);
                this.optionList.resetSortMapLastSave()
            } else {
                this.logger.log('Dont persist sortmap its legacy data')
            }
        }
    }

    /**
     * Persists  options in optionList as well as the sortmap
     * If force is not set persists only changed options and sortmap
     * Updates also the optionList with resetLastSave()
     */
    public persist(force: boolean = false) {
        this.logger.log(' persist')
        this._optionList.forEachIncludingDeleted(optionClass => {
            if (force || this._optionList.optionHasChangedSinceLastSave(optionClass.index)) {
                this.persistOption(optionClass);
            }
        })
        this.persistSortMap(force)
        this._optionList.resetLastSave()
    }

    /**
   * save all options that are created by a the current particpant (owner options are not saved)
   * @param name
   */
    public saveOptionsCreatedByParticpant(name: string) {
        this._optionList.forEach(option => {
            if (option.isCreatedByParticipant && option.createdByUid === this.authService.uid) {
                option.createdByName = name;
                this.persistOption(option);
            }
        });
    }

    public markAsCreatedByParticpant(option: OptionClass) {
        option.setMarkAsCreatedBy(this.authService.displayName, this.authService.uid)
    }

    public get dateOptions(): OptionClass[] {
        return this._optionList.dateOptions
    }

    /**
     * Creates a copy of an OptionList and persists it.
     * Waits
     * @param ownerId - The ID of the current owner.
     * @param pollId - The ID of the current poll.
     * @param newOwnerId - The ID of the new owner.
     * @param newPollId - The ID of the new poll.
     * @returns A promise that resolves to the new OptionList
     */
    public async persistCopyOfOptionList(ownerId: string, pollId: string, newOwnerId: string, newPollId: string): Promise<OptionList> {
        try {
            let optionsData: OptionData[] | OptionListData = await (firstValueFrom(this.getObservable(ownerId, pollId)))
            if (optionsData) {
                const optionList = new OptionList(optionsData)
                this.logger.log('persistCopyOfOptionList: optionlist: ', optionList)
                const persistPromises: Promise<void>[] = []
                optionList.forEachIncludingDeleted((optionClass, index) => {
                    persistPromises.push(this.persistCopyOfOption(optionClass, newOwnerId, newPollId, index, optionList.isLegacyData))
                })
                persistPromises.push(this.persistCopyOfSortMap(optionList, newOwnerId, newPollId))
                await Promise.all(persistPromises);
                return optionList
            } else {
                this.logger.warn('persistCopyOfOptionList: no such options', ownerId, pollId)
                return null
            }
        } catch (exception) {
            this.errorService.newError('persistCopyOfOptionList: ' + exception)
        }
    }



    private persistCopyOfOption(option: OptionClass, newOwnerId: string, newPollId, index: number, isLegacyData: boolean) {
        this.logger.log('persistCopyOfOption', option);
        if (!option) throw this.errorService.newError('persistCopyOfOption: option undefined');
        option.createdAt = firebase.database.ServerValue.TIMESTAMP;
        option.updatedAt = firebase.database.ServerValue.TIMESTAMP;;
        let path: string;
        if (isLegacyData) {
            path = `${this.pathService.getOptionsPath(newOwnerId, newPollId)}/${index}`
        } else {
            path = `${this.pathService.getOptionsArrayPath(newOwnerId, newPollId)}/${index}`
        }
        const ref: AngularFireObject<OptionData> = this.afd.object(path);
        return ref.set(option.optionData);
    }

    private persistCopyOfSortMap(optionList: OptionList, newOwnerId: string, newPollId: string) {
        if (!optionList.isLegacyData) {
            this.logger.log('copySortMap sortmap')
            const ref: AngularFireObject<number[]> = this.afd.object(`${this.pathService.getOptionsSortMapPath(newOwnerId, newPollId)}`);
            return ref.set(this._optionList.sortMap);
        } else {
            this.logger.log('Dont persist sortmap its legacy data')
        }
    }

    public deleteOptions(ownerId: string, pollId: string) {
        const ref: AngularFireObject<any> = this.afd.object(this.pathService.getOptionsPath(ownerId, pollId));
        ref.remove();
        const sortMapRef: AngularFireObject<number[]> = this.afd.object(`${this.pathService.getOptionsSortMapPath(ownerId, pollId)}`);
        sortMapRef.remove();
    }

}
