import { Injectable } from "@angular/core";
import { NGXLogger as LoggerService } from "ngx-logger";
import { RouteContextService } from "../../shared/services/route-context.service";
import { AuthService } from "../../user/auth.service";
import { ResponsiveService } from "../../shared/services/responsive.service";
import { CurrentPollService } from "../../whoo/core/current-poll.service";
import { TemplateService } from "../../whoo/core/poll-templates/template.service";

@Injectable({
    providedIn: 'root'
})

export class AppShellService {
    constructor(
        private logger: LoggerService,
        public routeContextService: RouteContextService,
        public authService: AuthService,
        public responsiveService: ResponsiveService,
        public currentPollService: CurrentPollService,
        public templateService: TemplateService
    ) {
    }
    get isFullScreen() {
        const isVoteOnInvitation = this.routeContextService.id === 'vote' && this.templateService.getConfigAttribute('general', 'formToUse') === 'invitation'
        if (this.routeContextService.id === 'editPoll' || this.routeContextService.id === 'createPoll') return true
        if (!(this.routeContextService.isFullScreen || isVoteOnInvitation)) return false;
        if (this.getShowContextBar()) return false
        return true
    }

    public getShowContextBar(): boolean {
        if (this.routeContextService.id === 'pollResponses' ||
            this.routeContextService.id === 'votePreview' ||
            this.routeContextService.id === 'votePreviewResponse') {
            return true
        }
        return false
    }

    public getShowBanner(): boolean {
        if (
            this.routeContextService.id === 'editPoll' ||
            this.routeContextService.id === 'createPoll' ||
            this.routeContextService.id === 'vote' ||
            this.routeContextService.id === 'votePreview' ||
            this.routeContextService.id === 'voteResponse' ||
            this.routeContextService.id === 'votePreviewResponse') {
            return false
        }
        return true
    }

    get showFeedbackButton(): boolean {
        if (this.responsiveService.isScreenBelowMedium()) {
            return false;
        } else {
            if (this.routeContextService.id !== 'editPoll' &&
                this.routeContextService.id !== 'votePreview' &&
                this.routeContextService.id !== 'vote'
            ) {
                return true
            }
            return false

        }
    }

    get showFooterInApp(): boolean {
        if (
            this.routeContextService.id === 'votePreviewResponse'
        ) {
            return true;
        }
        return false
    }
}


