<div class="bar-container-container">
    <fade-in-and-out [isVisible]="googleAnalytics.showCookieConsent" maxHeight="250px">

        <div class="bar-container">
            <div class="bar">
                <div class="title">{{label('title')}}</div>
                <p class="text">
                    {{label('text')}}
                    <a routerLink="{{trans.getRoutePath('privacy')}}">
                        {{label('privacyPolicy')}}
                    </a>.
                </p>
                <div class="buttons-container">
                    <button mat-stroked-button (click)="openDialog()">{{label('settingsButton')}}</button>
                    <button mat-raised-button color="accent" (click)="googleAnalytics.setConsentGranted()">
                        {{label('agreeButton')}}</button>
                </div>
            </div>
        </div>
    </fade-in-and-out>

</div>