export interface TimePeriodData {
    date?: string; // date in jsonformat e.g.: 2024-03-26T23:00:00.000Z
    startHour?: string; // hour including minutes e.g. : 13:00
    endHour?: string; // hour including minutes e.g. : 13:00
}

export interface HourOption {
    value: string;
    label: string;
}
export class TimePeriodClass {
    static allDayLabel = 'ALL DAY' // the default that can be overwritten
    private _timePeriodData: TimePeriodData = {};

    /**
     * sets default date and time if not provide
     * @param startDate
     * @param startTimeString
     * @param endTimeString
     */
    constructor(startDate: Date | TimePeriodData | null | undefined, startTimeString?: string, endTimeString?: string) {
        if (startDate?.constructor?.name === 'Date') {
            this.setStartDate(<Date>startDate)
            this.setDefaultTime()
        } else if (!startDate) {
            this.reset()
        } else if (startDate?.constructor?.name === 'Object') {
            this._timePeriodData = <TimePeriodData>startDate // sic! by reference !! we need this for OptionClass where we use TimePeriodClass
            if (!this.timePeriodData.date) {
                this.setStartDate()
            }
            if (this.timePeriodData.startHour === undefined) {
                this.setDefaultTime()
            }
        }
        if (startTimeString != null) {
            if (startTimeString === "") {// all day
                this.startTimeString = ""
                this.endTimeString = ""

            } else {
                this.startTimeString = startTimeString
                if (endTimeString !== null) {
                    this.endTimeString = endTimeString
                } else {
                    this.endTimeString = TimePeriodClass.addHour(startTimeString)
                }
            }
        }
    }


    /**
     * @depricated use TimePeriodClass and it's methods instead
     */
    get timePeriodData(): TimePeriodData {
        return this._timePeriodData
    }
    /**
     * @depricated use TimePeriodClass and it's methods instead
     */
    set timePeriodData(value: TimePeriodData) {
        this._timePeriodData = value // by reference !! we need this for OptionClass where we use TimePeriodClass
        if (!this.startTimeString) { this.setDefaultTime() }
        if (!this.startDateString) (this.setStartDate())
    }

    /**
     * returns the start date including hours and minutes
     */
    get startDateTime(): Date {
        const returnValue = new Date(this.startDateString)
        if (!this.isAllDay) {
            returnValue.setHours(this.getHoursAsNumber(this.startTimeString));
            returnValue.setMinutes(this.getMinutesAsNumber(this.startTimeString));
        }
        return returnValue;
    }

    /**
    * returns the end date including hours and minutes
     */
    get endDateTime(): Date {
        const returnValue = new Date(this.startDateString)
        if (!this.isAllDay) {
            returnValue.setHours(this.getHoursAsNumber(this.endTimeString));
            returnValue.setMinutes(this.getMinutesAsNumber(this.endTimeString));
        }
        return returnValue
    }

    private getHoursAsNumber(hourString: string): number {
        return Number(hourString.split(':')[0])
    }
    private getMinutesAsNumber(hourString: string): number {
        return Number(hourString.split(':')[1])
    }

    private reset() {
        this._timePeriodData = {}
        this.setDefaultTime()
        this.setStartDate();
    }

    /**
     * changes only the date not the hours and minutes,
     * @param date  if not provided date is set to today
     */
    public setStartDate(date?: Date) { //
        if (!date) date = new Date() // default is today
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.startDateString = date.toJSON()
    }

    private setDefaultTime() {
        this.startTimeString = '12:00'
        this.endTimeString = '13:00'
    }

    get isAllDay(): boolean {
        return (this._timePeriodData.startHour === '') ||
            (this._timePeriodData.endHour === '')
    }

    set isAllDay(value: boolean) {
        if (value) {
            this._timePeriodData.startHour = ''
            this._timePeriodData.endHour = ''
        } {
            this.setDefaultTime()
        }

    }

    /** date in jsonformat e.g.: 2024-03-26T23:00:00.000Z */
    get startDateString(): string {
        return this._timePeriodData.date
    }

    /** date in jsonformat e.g.: 2024-03-26T23:00:00.000Z */
    set startDateString(value: string) {
        this._timePeriodData.date = value
    }

    /** time in format e.g.: 13:00 */
    get startTimeString(): string {
        return this._timePeriodData.startHour
    }


    /** time in format e.g.: 13:00 */
    set startTimeString(value: string) {
        this._timePeriodData.startHour = value
    }

    /** time in format e.g.: 13:00 */
    get endTimeString(): string {
        return this._timePeriodData.endHour
    }

    /** time in format e.g.: 13:00 */
    set endTimeString(value: string) {
        this._timePeriodData.endHour = value
    }


    public isStartDateTimeEqual(timePeriod: TimePeriodClass): boolean {
        const value = TimePeriodClass.compareStartDateTime(this.timePeriodData, timePeriod.timePeriodData)
        if (value == 0) {
            return true
        } else {
            return false
        }
    }


    static areDatesEqual(date1: Date, date2: Date): boolean {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }

    /**
     * Compared start date and time
     * @param timePeriodA
     * @param timePeriodB
     * @returns
     */
    static compareStartDateTime(timePeriodA: TimePeriodData, timePeriodB: TimePeriodData): 1 | 0 | -1 {
        if (timePeriodA.date < timePeriodB.date) {
            return -1;
        }
        if (timePeriodA.date > timePeriodB.date) {
            return 1;
        }
        if (timePeriodA.date === timePeriodB.date) {
            if (timePeriodA.startHour < timePeriodB.startHour) {
                return -1;
            }
            if (timePeriodA.startHour > timePeriodB.startHour) {
                return 1;
            }
        }
        return 0;
    }


    /**
     * Makes from 13:15 => 14:15
     * @param hourString Format 13:15
     * @returns
     */
    static addHour(hourString: string): string {
        if (hourString === '') {
            return '00:00';
        }
        let hours: string = hourString.substr(0, 2);
        const minutes = hourString.substr(3, 2);
        let hoursNumber = parseInt(hours, 10);
        if (hoursNumber < 23) {
            hoursNumber = hoursNumber + 1;
        }
        hours = hoursNumber.toString();
        if (hours.length < 2) {
            hours = '0' + hours;
        }
        return hours + ':' + minutes;
    }

    /**
     *
     * @param hour1
     * @param hour2
     * @returns true if hour1 > hour2, false otherwise
     */
    static isTimeStringBiggerThan(hour1: string, hour2: string): boolean {
        const hours1 = parseInt(hour1.substr(0, 2), 10);
        const hours2 = parseInt(hour2.substr(0, 2), 10);
        const minutes1 = parseInt(hour1.substr(3, 2), 10);
        const minutes2 = parseInt(hour2.substr(3, 2), 10);
        if (hours1 > hours2) {
            return true;
        }
        if (hours1 === hours2) {
            if (minutes1 > minutes2) {
                return true;
            }
        }
        return false;
    }




    public toString(): string {
        return TimePeriodClass.toString(this._timePeriodData)
    }

    static toString(timePeriodData: TimePeriodData) {
        const date = new Date(timePeriodData.date);
        let returnValue = date.toLocaleDateString();
        if (!TimePeriodClass.isAllDay(timePeriodData)) {
            returnValue += ", " + timePeriodData.startHour + " - " + timePeriodData.endHour
        } else {
            returnValue += ", " + this.allDayLabel

        }
        return returnValue
    }

    static isAllDay(timePeriod): boolean {
        return timePeriod.startHour === ''

    }

    static setAllDAyLabel(allDayLabel: string) {
        this.allDayLabel = allDayLabel
    }


    static getHourOptions(): { startHourOptions: HourOption[], endHourOptions: HourOption[] } { // @todo: move to time Period class as static function
        const hourOptions = [];
        let startHourOptions: HourOption[]
        let endHourOptions: HourOption[]

        // loop from 0 to 24
        for (let index = 0; index < 24; index++) {
            let hour: string;
            if (index < 10) {
                hour = '0' + index.toString();
            } else {
                hour = index.toString();
            }
            const zero = hour + ':00';
            const fifteen = hour + ':15';
            const thirty = hour + ':30';
            const fourtyFive = hour + ':45';
            hourOptions.push({ value: zero, label: zero });
            hourOptions.push({ value: fifteen, label: fifteen });
            hourOptions.push({ value: thirty, label: thirty });
            hourOptions.push({ value: fourtyFive, label: fourtyFive });
        }
        startHourOptions = [
            ...hourOptions,
            { value: '', label: TimePeriodClass.allDayLabel },
        ];
        // add the all day just before 12:00
        startHourOptions.splice(48, 0, {
            value: '',
            label: TimePeriodClass.allDayLabel,
        });
        endHourOptions = [{ value: '', label: '' }, ...hourOptions];

        return { startHourOptions: startHourOptions, endHourOptions: endHourOptions }
    }

}
