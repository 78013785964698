/**
 * without template e.g. 'header.titleFieldPlaceholder'
 * with template e.g. 'schedule.header.titleFieldPlaceholder'
 * If now templatet key is provided uses the template that is initialized
 */

import { Pipe, PipeTransform } from "@angular/core"
import { TemplateService } from "../template.service"
import { essTranlatePipeTransform, EssTranslationService } from "ngx-essentia"


/**
 *
 * @param keys : with or without template e.g. "advanced.header.titleFieldLabel " "header.titleFieldLabel "
 * @returns
 */
@Pipe({
    standalone: true,
    name: 'transTemplate',
})
export class TranslateTemplatePipe implements PipeTransform {
    constructor(
        private essTrans: EssTranslationService,
        private templateService: TemplateService,
    ) { }
    async transform(keys: string) {
        let templateKey: string;
        const valueSplit = keys.split('.')
        if (valueSplit.length === 3) {
            templateKey = valueSplit[0]
        } else {
            templateKey = this.templateService.templateKey
        }
        const templateFallBackHierarchy = this.templateService.getTemplateHierarchy(templateKey) // @todo allow templateKey to be a parameter - see templateLabelPipe
        return essTranlatePipeTransform(this.essTrans, keys, 'template', templateFallBackHierarchy)
    }
}
