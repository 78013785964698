<ess-dialog-title>{{ 'share-poll' | transMigrated}}</ess-dialog-title>
<mat-dialog-content class="dialog-content">

    <share-poll [showHeadline]="true" [copyToClipboardOnInit]="true"></share-poll>

</mat-dialog-content>


<ess-dialog-actions confirmLabel="{{ 'got-it'  | transMigrated}}" (confirmEvent)="dialogRef.close()"
    [showCancel]="false">
</ess-dialog-actions>