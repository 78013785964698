import { Component, OnInit } from '@angular/core';

import { EssTranslationService } from 'ngx-essentia';


@Component({
    template: '',

})
export abstract class AbstractLandingPage implements OnInit {
    abstract templateKey: string
    translationLoaded = false;
    constructor(
        protected essTrans: EssTranslationService,
    ) { }

    async ngOnInit(): Promise<void> {
        await this.loadTranslation()
    }
    async loadTranslation() {
        await this.essTrans.load('page/' + this.templateKey.toLowerCase())
        //  await new Promise(resolve => setTimeout(resolve, 5000));
        this.translationLoaded = true
    }
    get labelPrefix() {
        return this.templateKey + '.landingPage.'
    }
}
