<top-background></top-background>
<div [ngClass]="responsiveService.getBigSmall()" class="page-container whoo-nav-bar-margin">
    <div class="whoo-section-container section-1-container">
        <div class="section-1">
            <div class="spiral-arrow-left-container">
                <div class="spiral-arrow-left">
                </div>
            </div>

            <!--left useStandardContent-->
            <div *ngIf="useStandardContent" class="content-container">
                <div class="spiral-arrow-top-mobile-container ">
                    <div class="spiral-arrow-top-mobile">
                    </div>
                </div>
                <h1>
                    <span>{{labelPrefix + 'h1Line1' | transPage }}</span>
                    <br>
                    <span>{{labelPrefix + 'h1Line2'| transPage }}</span>
                </h1>

                <div class="h1-h2-divider">
                    <img src="assets/images/two-stripes.png">
                </div>
                <h2>{{labelPrefix + 'h2'| transPage }}</h2>
                <p>
                    <span>{{labelPrefix + 'paragraph1'| transPage }}</span>&nbsp;
                    <b><span>{{labelPrefix + 'paragraph2'| transPage }} </span> </b>
                </p>
                <div class="create-poll-button-container">
                    <create-poll-button [pollTemplateKey]="templateKey"
                        titleFieldLabel="{{labelPrefix + 'titleFieldLabel'| transPage }}"
                        titleFieldLabelShort="{{labelPrefix + 'titleFieldLabelShort'| transPage }}"
                        buttonLabel="{{labelPrefix + 'buttonLabel'| transPage }}">
                    </create-poll-button>
                </div>
            </div>

            <!--left NOT useStandardContent-->
            <div *ngIf="!useStandardContent" class="content-container">
                <ng-content></ng-content>
            </div>

            <!--right-->
            <div class="image-container">
                <div class="bubble-small-container">
                    <div class="bubble-small">
                    </div>
                </div>
                <div class="bubble-big-container">
                    <div class="bubble-big">
                    </div>
                </div>
                <div class="spiral-arrow-mobile-container">
                    <div class="spiral-arrow-mobile">
                    </div>
                </div>
                <div class="circle-mobile-container">
                    <div class="circle-mobile">
                    </div>
                </div>
                <img src="{{imageSource}}" alt="Create your online invitation">
                <div class="spiral-arrow-right-container">
                    <div class="spiral-arrow-right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>