<div [ngClass]="responsiveService.getBigSmall()">
    <form autocomplete="off">
        <div class="create-poll ">
            <span *ngIf="showField" class="create-poll-field">
                <mat-form-field appearance="outline">
                    <mat-label>{{fieldLabel}}</mat-label>
                    <input matInput [(ngModel)]="pollTitle" maxlength="150" autocomplete="off" name="poll-title"
                        id="poll-title">
                </mat-form-field>
            </span>
            <span class="button-container">
                <button mat-raised-button (click)="createPoll()" color="accent"
                    class="whoo-create-poll-button">{{buttonLabelResponsive }}</button>
            </span>
        </div>
    </form>
</div>
