import { Injectable } from "@angular/core";
import { NGXLogger as LoggerService } from "ngx-logger";
import { BannerInterface } from "./banner.interface";

@Injectable({
    providedIn: 'root',
})

export class BannerService {
    private bannerQueue: BannerInterface[] = [];
    constructor(
        private logger: LoggerService,

    ) { }

    public registerBanner(banner: BannerInterface) {
        this.enqueueBanner(banner)
        this.logger.log('registerBanner', this.bannerQueue)
    }

    private enqueueBanner(banner: BannerInterface) {
        let isAdded = false;
        for (let i = 0; i < this.bannerQueue.length; i++) {
            if (banner.priority > this.bannerQueue[i].priority) {
                this.bannerQueue.splice(i, 0, banner)
                this.logger.log('enqueueBanner added banner', this.bannerQueue)
                isAdded = true;
                break
            }
        }
        if (!isAdded) {
            this.bannerQueue.push(banner)
        }
    }

    private popBanner(banner: BannerInterface) {
        const index = this.bannerQueue.indexOf(banner);
        if (index !== -1) {
            this.logger.log('popBanner', banner)
            this.bannerQueue.splice(index, 1);
            this.logger.log('popBanner new BannerQueue', this.bannerQueue)
        }
    }

    get banner() {
        // skip the banners that shoul not be shown
        for (let i = 0; i < this.bannerQueue.length; i++) {
            if (this.bannerQueue[i].getShowBanner()) return this.bannerQueue[i]
        }
        return null
    }
    get showBanner(): boolean {
        if (this.banner) {
            return this.banner.getShowBanner()
        } else {
            return false
        }
    }
    get message(): string {
        return this.banner.message;
    }
    get icon(): string {
        return this.banner.icon
    }
    get leftButtonLabel(): string {
        return this.banner.leftButtonLabel
    }
    get rightButtonLabel(): string {
        return this.banner.rightButtonLabel
    }

    public handleLeftButtonClick() {
        this.banner.handleLeftButtonClick()
        this.popBanner(this.banner)
        this.logger.log("handleRightButtonClick", this.bannerQueue)
    }

    public handleRightButtonClick() {
        this.banner.handleRightButtonClick()
        this.popBanner(this.banner)
        this.logger.log("handleRightButtonClick", this.bannerQueue)
    }

}
