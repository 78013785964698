import { Injectable } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { routesSeo as routesSeoEn } from '../../locale/en/routes-seo';
import { routesSeo as routesSeoDe } from '../../locale/de/routes-seo';
import { routesSeo as routesSeoIt } from '../../locale/it/routes-seo';
import { routesSeo as routesSeoEs } from '../../locale/es/routes-seo';
import { routesSeo as routesSeoFr } from '../../locale/fr/routes-seo';
import { routesSeo as routesSeoPt } from '../../locale/pt/routes-seo';
import { EssTranslationService } from 'ngx-essentia';

export interface language {
    id: string,
    englishName: string,
    name: string
}
export const languages: language[] = [
    { id: "de", englishName: "German", name: "Deutsch" },
    { id: "en", englishName: "English", name: "English" },
    { id: "es", englishName: "Spanish", name: "Español" },
    { id: "fr", englishName: "French", name: "Français" },
    { id: "it", englishName: "Italian", name: "Italiano" },
    { id: "pt", englishName: "Portugiese", name: "Português" },
]

@Injectable({
    providedIn: 'root'
})
/**
 * @depricated
 */
export class TranslationGeneralService {
    private scopeGeneral = 'general'
    private scopeMigrated = 'migrated'
    private routeSeo = null;
    public languages = languages;
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        private essTrans: EssTranslationService,
    ) {
        this.initLanguage();
    }

    public async loadTransLationFiles() {
        await Promise.all([
            this.essTrans.load(this.scopeGeneral),
            this.essTrans.load(this.scopeMigrated)
        ]);

        this.logger.log('General translation file loaded')
    }
    /**
     *
     * @param keys e.g "testTranslation.success" or "test.translation.success"
     * @returns translated value in the current language (if exists) else the english version
     *  from the file projects/whocan-app/src/assets/locale/en/general/general.en.dic.json
     */
    public translate(keys: string) {
        const returnValue = this.essTrans.translate(keys, this.scopeGeneral)
        return returnValue
    }

    public translateMigrated(keys: string) {
        const returnValue = this.essTrans.translate(keys, this.scopeMigrated)
        return returnValue
    }


    public isLanguageImplemented(languageCode: string): boolean {
        const findResult = languages.find(lang => lang.id === languageCode)
        this.logger.log('isLanguageImplemented', languageCode, findResult)
        if (findResult) return true;
        return false
    }



    private initLanguage() {
        if (this.local === 'de') {
            this.routeSeo = routesSeoDe;
        } else if (this.local === 'pt') {
            this.routeSeo = routesSeoPt;

        } else if (this.local === 'it') {
            this.routeSeo = routesSeoIt;

        } else if (this.local === 'fr') {
            this.routeSeo = routesSeoFr;

        } else if (this.local === 'es') {
            this.routeSeo = routesSeoEs;

        } else {// the default is
            this.routeSeo = routesSeoEn;
        }
    }



    get local(): string {
        return this.locale.substring(0, 2);
    }

    public getRoutePath(pathId: string): string {
        let returnValue: string = this.routeSeo[pathId];
        if (returnValue === undefined) {
            returnValue = routesSeoEn[pathId];
        }
        if (!returnValue || returnValue === undefined || returnValue.startsWith('/')) {
            return returnValue;
        }
        //    this.logger.log('getRoutePath', returnValue);
        return '/' + returnValue;
    }

    public getShortTitle(id: string) {
        let returnValue = this.routeSeo[id + '.shortTitle'];
        if (returnValue === undefined) {
            returnValue = routesSeoEn[id + '.shortTitle'];
        }
        return returnValue;
    }

    public getSeoTitle(id: string) {
        let returnValue = this.routeSeo[id + '.title'];

        if (returnValue === undefined) {
            returnValue = routesSeoEn[id + '.title'];
        }
        return returnValue;
    }

    public getSeoDescription(id: string) {
        let returnValue = this.routeSeo[id + '.description'];
        if (returnValue === undefined) {
            returnValue = routesSeoEn[id + '.description'];
        }
        return returnValue;

    }

}
