
import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { LOCALE_ID, Inject } from '@angular/core';
import { CurrentPollService } from './current-poll.service';
import { AuthService } from '../../user/auth.service';
import { paths } from 'functions-lib';
import { EssErrorService } from 'ngx-essentia';
@Injectable({
    providedIn: 'root'
})
/**
 * Service that holds the current ownerId and pollId which are set with initService()
 * Components can use changeIndicator to get informed if the current poll has changed
 * Use this service to get the paths to the DB and the URLs
 */
export class PathService {

    constructor(
        private logger: LoggerService,
        @Inject(LOCALE_ID) public locale: string,
        private authService: AuthService,
        private currentPollService: CurrentPollService,
        private errorService: EssErrorService
    ) {
    }

    /**
     * Returns something like ${PATH_TO_OPTIONS}/${this.ownerId}/${this.pollId
     */
    public getOptionsPath(ownerId?: string, pollId?: string): string {
        if (!ownerId || !pollId) {
            if (!this.currentPollService.isInitialized) {
                this.errorService.newError('getOptionsPath poll not initialized - ownerId' +
                    ownerId ? ownerId : 'No ownerId ' + ' pollId: ' +
                        pollId ? pollId : 'No pollId')
            }
        }

        return paths.options + '/' + this.currentPollService.getOwnerId(ownerId) + '/' + this.currentPollService.getPollId(pollId);
    }


    public getOptionsArrayPath(ownerId?: string, pollId?: string): string {
        if (!ownerId || !pollId) {
            if (!this.currentPollService.isInitialized) {
                this.errorService.newError('getOptionsPath poll not initialized - ownerId' +
                    ownerId ? ownerId : 'No ownerId ' + ' pollId: ' +
                        pollId ? pollId : 'No pollId')
            }
        }
        return paths.options + '/' +
            this.currentPollService.getOwnerId(ownerId) + '/' +
            this.currentPollService.getPollId(pollId) + '/' +
            paths.optionArray;
    }


    public getOptionsSortMapPath(ownerId?: string, pollId?: string): string {
        if (!ownerId || !pollId) {
            if (!this.currentPollService.isInitialized) {
                this.errorService.newError('getOptionsPath poll not initialized - ownerId' +
                    ownerId ? ownerId : 'No ownerId ' + ' pollId: ' +
                        pollId ? pollId : 'No pollId')
            }
        }
        return paths.options + '/' +
            this.currentPollService.getOwnerId(ownerId) + '/' +
            this.currentPollService.getPollId(pollId) + '/' +
            paths.optionSortMap;
    }


    public getEmailPath(): string {
        return paths.emails.toBeProcessed
    }
    public getCurrentTimePath(): string {
        return paths.currentTime
    }
    /**
     * Returns something like ${PATH_TO_SURVEY}/${ownerId}/${pollId}
     */
    public getHeaderPath(ownerId?: string, pollId?: string): string {
        this.logger.log('getHeaderPath: ', ownerId, pollId);
        return paths.header + '/' + this.currentPollService.getOwnerId(ownerId) + '/' + this.currentPollService.getPollId(pollId);
    }

    /**
     * Returns something like ${PATH_TO_VOTES}/${ownerId}/${pollId}
     */
    public getVotesPath(ownerId?: string, pollId?: string): string {
        return paths.votes + '/' + this.currentPollService.getOwnerId(ownerId) + '/' + this.currentPollService.getPollId(pollId);
    }

    public getUserVotePath(userId: string, ownerId?: string, pollId?: string): string {
        this.logger.log('getUserVotePath: userId: ' + userId);
        return paths.votes + '/' + this.currentPollService.getOwnerId(ownerId) + '/' + this.currentPollService.getPollId(pollId) + '/' + userId;
    }

    public getSurveyParticipatedPath(ownerId: string, pollId: string, voteUserId: string): string {
        return paths.participation + '/' + voteUserId + '/' + ownerId + '-' + pollId;
    }

    /**
     * Returns something like "surveys-header/eQjTCpFdYTPNjYC5MsKZwM5Lqvx1"
     * @param ownerId
     */
    public getPollsOwnedPath(ownerId?: string): string {
        return paths.header + '/' + this.currentPollService.getOwnerId(ownerId);
    }

    public getSurveysParticipatedPath(uid?): string {
        return paths.participation + '/' + this.getUid(uid);
    }

    private getUid(uid?: string): string {
        return uid ? uid : this.authService.uid;
    }

}
