import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgxEssentiaModule } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { SharePollComponent } from '../share-poll.component';
import { CurrentPollService } from '../../../core/current-poll.service';
import { TranslateMigratedPipe } from '../../../../shared/translation/trans-migrated.pipe';

@Component({
    selector: 'share-poll-dialog',
    standalone: true,
    imports: [
        MatDialogModule,
        CommonModule,
        NgxEssentiaModule,
        SharePollComponent,
        TranslateMigratedPipe
    ],
    templateUrl: 'share-poll-dialog.component.html',
    styleUrls: ['share-poll-dialog.component.scss'],
})
export class SharePollDialogComponent {
    constructor(
        public logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<SharePollDialogComponent>,
        public dialog: MatDialog,
        public currentPollService: CurrentPollService
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
