import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService } from 'ngx-essentia';

import { environment } from 'projects/whocan-app/src/environments/environment';
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { RouteContextService } from '../../../shared/services/route-context.service';
import { TranslationGeneralService } from '../../../shared/translation/translation.general.service';
import { UIMesageService } from '../../../shared/services/ui-message.service';
import { AuthService } from '../../../user/auth.service';
import { UserService } from '../../../user/user.service';
import { HeaderService } from '../../../whoo/shared/poll-header/header.service';
import { NavigationService } from '../../../whoo/core/navigation.service';
import { CommonModule } from '@angular/common';
import { LogoComponent } from '../../../shared/logo/logo.component';
import { RouterModule } from '@angular/router';
import { NavCreatePollButtonComponent } from '../create-poll-button/create-poll-button.component';
import { NavMenuButtonComponent } from '../../../shared/menu-button/menu-button.component';
import { AuthErrorService } from '../../../user/auth-error.service';
import { TranslateMigratedPipe } from '../../../shared/translation/trans-migrated.pipe';

@Component({
    selector: 'nav-bar',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        LogoComponent,
        NavCreatePollButtonComponent,
        NavMenuButtonComponent,
        TranslateMigratedPipe
    ],
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
    public qrCode: string = null;
    dialogRef: MatDialogRef<unknown>;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        private uiMessage: UIMesageService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        public headerService: HeaderService,
        public routeContextService: RouteContextService,
        private navigationService: NavigationService,
        public trans: TranslationGeneralService,
        private dialogService: EssDialogService,
        private authErrorService: AuthErrorService,
    ) { }

    async logout() {
        try {
            this.navigationService.navigate(this.trans.getRoutePath('index'));
            await this.authService.logOut();
            this.uiMessage.success(this.trans.translate('logout.success'));
        } catch (error) {
            this.authErrorService.handleError(error)
        }
    }

    public getHomeButtonName(): string {
        return environment.home_button_label_short;
    }

    public async openMySurveys() {
        this.navigationService.navigate(this.trans.getRoutePath('myPolls'));
    }

    get disableCreatePollButton(): boolean {
        if (this.routeContextService.id === 'createPoll') {
            return true;
        }
        return false;
    }

    openCreatePollDialog(): void {
        this.dialogRef?.close(); // make sure we don't have multiple dialog open
        import('../../../whoo/shared/poll/create-poll-dialog/create-poll-dialog').then(
            (module) => {
                this.dialogRef = this.dialogService.open(
                    module.CreatePollDialog,
                    null
                );
            }
        );
    }
}
