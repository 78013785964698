<div *ngIf="!isClosed" [ngClass]="type" class="component-container">
    <mat-card>
        <div class="whoo-left-right">
            <span class="left"></span>
            <span (click)="close()" class="x-close">X</span>
        </div>
        <div class="card-content">
            <div class="whoo-left-right">
                <span class="message">{{message}}</span>
            </div>
            <div class="description">{{description}}</div>
            <div *ngIf="goToText && goToLink" class="whoo-left-right">
                <span></span>
                <span class="buttons">
                    <button mat-button (click)="goToRoute()" color="accent">{{goToText}}</button>
                </span>
            </div>
        </div>

    </mat-card>

</div>