import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';
import { TranslateLandingPagePipe } from 'ngx-essentia';

@Component({
    selector: 'testimonial-standalone',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        TranslateLandingPagePipe,
    ],
    templateUrl: './testimonial-standalone.html',
    styleUrls: ['./testimonial-standalone.scss'],
})
export class TestemonialStandalone {
    @Input() name: string;
    @Input() imageUrl: string;
    @Input() templateKey: string;
    constructor(
        public responsiveService: ResponsiveService,
        private templateService: TemplateService,

    ) { }

    get labelPrefix() {
        return this.templateKey + '.landingPageTestimonial.'
    }

    getName() {
        if (this.name) return this.name
        return "";
    }

}
