import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TranslationGeneralService } from '../../../../shared/translation/translation.general.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateMigratedPipe } from '../../../../shared/translation/trans-migrated.pipe';

@Component({
    selector: 'how-it-works-item',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatTooltipModule,
        TranslateMigratedPipe
    ],
    templateUrl: './how-it-works-item.html',
    styleUrls: ['./how-it-works-item.scss'],
})
export class HowItWorksItem implements OnInit {
    @Input() title: string;
    @Input() imageUrlBigScreen: string;

    @Input() imageUrlSmallScreen: string;
    imageUrlDe: string;
    @Input() description: string;
    @Input() imageRight = true;

    private dialogRef: MatDialogRef<unknown>;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        private dialogService: EssDialogService,
    ) { }

    ngOnInit() { }
    get imageUrl() {
        if (this.imageUrlSmallScreen && this.responsiveService.isScreenSmall()) {
            return this.imageUrlSmallScreen;
        }
        //   this.logger.log('imageUrl', this.imageUrlBigScreen)
        return this.imageUrlBigScreen;
    }



    public showImage(): void {
        this.dialogRef?.close(); // make sure we don't have multiple dialog open
        const data = {
            title: this.title,
            description: this.description,
            imageUrl: this.imageUrl,

        }
        import('../../../../shared/show-image-dialog/show-image-dialog').then(
            (module) => {
                this.dialogRef = this.dialogService.open(
                    module.ShowImageDialog,
                    data
                );
            }
        );
    }
}
