<div class="component-container">
    <span
        *ngIf="responsiveService.isScreenSmall() && false"><!--hide add button on mobile, makes the navigation cleaner - add button can be found on top of the menu-->
        <button mat-icon-button color="accent" aria-label="Add Poll"
            matTooltip="{{ 'choose-a-template-to-create-an-event-poll'  | transMigrated}}"
            (click)="openCreatePollDialog()" [disabled]="disableCreatePollButton">
            <mat-icon>add</mat-icon>
        </button>
    </span>
    <span *ngIf=" !responsiveService.isScreenSmall()">
        <span class="create-poll">
            <button mat-raised-button [color]="color" (click)="openCreatePollDialog()"
                matTooltip="Choose a template to create an event poll" [disabled]="disableCreatePollButton">{{ 'create'
                | transMigrated}}</button>
        </span>
    </span>
</div>