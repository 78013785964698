
import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { AuthService } from '../../user/auth.service';
import { EssErrorService } from 'ngx-essentia';



@Injectable({
    providedIn: 'root'
})
/**
 * Service that holds the current ownerId and pollId which are set with initService()
 * Components can use changeIndicator to get informed if the current poll has changed
 * Use this service to get the paths to the DB and the URLs
 */
export class CurrentPollService {
    private _ownerId: string;
    private _surveyId: string;
    private _voteUserId: string;
    public _isInitialized = false;
    public _isOwner = false;
    constructor(
        private logger: LoggerService,
        private authService: AuthService,
        private errorService: EssErrorService
    ) {
    }

    /**
     * Sets the ownerId and pollId for future calls and reinits all services that have registred with registerInitCallBack
     * Can be called also with empty pollId and OwnerIdto init the other services
     * @param ownerId UID of the owvner of the service
     * @param pollId poll id or index
     */
    public async setCurrentPoll(ownerId: string, pollId: string, voteUserId?: string) {
        this.logger.log('CurrentPollService: setCurrentPoll: ', ownerId, ' ', pollId, voteUserId);
        if (!ownerId || !pollId) throw (this.errorService.newError('setCurrentPoll error: ' + ownerId + ' ' + pollId))
        this.unInitializeCurrentPoll();
        if (!ownerId || !pollId || ownerId === '' || pollId === '') {
            this.logger.log('CurrentPollService:initService: ownerId and surveId should not be empty');
        }

        this._ownerId = ownerId;
        this._surveyId = pollId;
        this._voteUserId = voteUserId;
        this._isInitialized = true;
        const uid = await this.authService.getUid()
        this._isOwner = this.authService.hasEditRights(uid, this.ownerId)
        this.logger.log('CurrentPollService: initService: ', this);
    }

    public unInitializeCurrentPoll() {
        this.logger.log('unInitializeCurrentPoll ', this);
        this._ownerId = null;
        this._surveyId = null;
        this._voteUserId = null;
        this._isInitialized = false;
        this._isOwner = false
    }

    /**
     * Returns the ownerId
     * Might be called before init hence it return null
     */
    get ownerId() {
        return this._ownerId;
    }

    /**
     * Returns the pollId
     * Might be called before init hence it return null
     */
    get pollId() {
        return this._surveyId;
    }

    /**
     * Returns the voteUserId
     * Is neede if you want to access a users vote that was delegated
     */
    get voteUserId() {
        return this._voteUserId;
    }
    get isInitialized() {
        return this._isInitialized;
    }

    get changeIndicator(): string {
        if (this.pollId) { // we don't want to indicate in the begninning when pollId is still empty
            return this.ownerId + this.pollId;
        }
        return null;
    }


    get isOwner(): boolean {
        return this._isOwner

    }

    public getPollId(pollId?: string): string {
        this.checkInitRequired(pollId);
        return pollId ? pollId : this.pollId;
    }

    public getOwnerId(ownerId?: string): string {
        this.checkInitRequired(ownerId);
        return ownerId ? ownerId : this.ownerId;
    }

    public getVoteUserId(voteUserId?: string): string {
        if (!this.authService.isLoggedIn) {
            this.checkInitRequired(voteUserId);
        }
        if (voteUserId) { return voteUserId; }
        if (this.voteUserId) { return this.voteUserId; }
        return this.authService.uid;
    }


    private checkInitRequired(value?: string) {
        if (!value) {
            if (!this.isInitialized) {
                throw this.errorService.newError('CurrentPollService: Init Required');
            }
        }
    }


}
