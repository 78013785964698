import { Injectable } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger as LoggerService } from "ngx-logger";

import { firstValueFrom } from 'rxjs';

export interface language {
    id: string,
    englishName: string,
    name: string
}
export const languages: language[] = [
    { id: "de", englishName: "German", name: "Deutsch" },
    { id: "en", englishName: "English", name: "English" },
    { id: "es", englishName: "Spanish", name: "Español" },
    { id: "fr", englishName: "French", name: "Français" },
    { id: "it", englishName: "Italian", name: "Italiano" },
    { id: "pt", englishName: "Portugiese", name: "Português" },
]

@Injectable({
    providedIn: 'root'
})
export class EssTranslationService {
    public templateKey: string
    public languages = languages;
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        private transLoco: TranslocoService


    ) {
        this.initTransLoco();
        // this.testTranslatePage()
    }

    private async initTransLoco() {
        this.transLoco.setActiveLang(this.locale)
    }



    /**
     * load the translation of the current locale as well as english as default language
     * @param scope provide scope like "template", "page" if you want to load only a a specific scope,
     * keep emoty to load all scopes
     */
    public async load(scope?: string) {
        if (scope) {
            await this.loadScope(scope)
        } else {
            await this.loadAllScopes()
        }
    }

    private async loadScope(scope: string) {
        await firstValueFrom(this.transLoco.load(`${scope}/en`))
        await firstValueFrom(this.transLoco.load(`${scope}/${this.locale}`))
    }

    private async loadAllScopes() {
        firstValueFrom(this.transLoco.load('en'))
        firstValueFrom(this.transLoco.load(this.locale))
    }

    public getTemplateHyrarchie(templateKey: string): string[] {
        return ['advanced,', 'schedule', 'birthdayInvitation']
    }



    public translateUser(keys: string,) {
        return this.selectTranslate(keys, 'user')
    }


    /**
     * translation files need to bee loaded before , use load() for this
     * @param keys e.g "options.label"
     * @param scope1 e.g. "template", "page", "general"
     * @param scope2FallBackHierarchy  e.g. ['advanced', 'invitation','birthdayInvitation' ]; ['general']; ['default', 'doodle-alternative']
     * @returns
     */
    public translate(keys: string, scope1: string, scope2FallBackHierarchy?: string[]): string {
        let scopes, scope2: string;
        if (scope2FallBackHierarchy) {
            scope2 = scope2FallBackHierarchy[scope2FallBackHierarchy?.length - 1]
        }
        let returnValue: string;
        if (scope2) {
            scopes = scope1 + "/" + scope2
        } else {
            scopes = scope1
        }
        try {
            returnValue = this.transLoco.translate(keys, {}, scopes)
        } catch (error) { // happens if the file does not exist
            this.logger.error('Missing translation file: ', keys, scopes, scope2FallBackHierarchy)
        }
        if (!this.translationExist(keys, scopes, returnValue)) {
            if (scope2FallBackHierarchy && scope2FallBackHierarchy.length > 1) {
                scope2FallBackHierarchy.pop()
                returnValue = this.translate(keys, scope1, scope2FallBackHierarchy) // recursive call with one less element in the hierarchy
            }
        }
        //    this.logger.warn('translate key scope1 ', keys, scope1, scope2FallBackHierarchy, returnValue)
        return returnValue
    }

    /**
     * async version of translate() , translation files DONT have to be loaded before
     * @param keys e.g "options.label"
     * @param scope1 e.g. "template", "page", "general"
     * @param scope2FallBackHierarchy  e.g. ['advanced', 'invitation','birthdayInvitation' ]; ['general']; ['default', 'doodle-alternative']
     * @returns
     */
    public async selectTranslate(keys: string, scope1: string, scope2FallBackHierarchy?: string[]): Promise<string> {
        let scopes, scope2: string;
        if (scope2FallBackHierarchy) {
            scope2 = scope2FallBackHierarchy[scope2FallBackHierarchy?.length - 1]
        }
        let returnValue: string;
        if (scope2) {
            scopes = scope1 + "/" + scope2
        } else {
            scopes = scope1
        }
        try {
            returnValue = await firstValueFrom(this.transLoco.selectTranslate(keys, {}, scopes))
        } catch (error) { // happens if the file does not exist
            this.logger.error('Missing translation file: ', keys, scopes, scope2FallBackHierarchy)
        }
        if (!this.translationExist(keys, scopes, returnValue)) {
            if (scope2FallBackHierarchy && scope2FallBackHierarchy.length > 1) {
                scope2FallBackHierarchy.pop()
                returnValue = await this.selectTranslate(keys, scope1, scope2FallBackHierarchy) // recursive call with one less element in the hierarchy
            }
        }
        return returnValue
    }

    private translationExist(key, scope, translationString) {
        if (!translationString) return false
        if (translationString === this.getTransLocoMissingTransLationString(key, scope)) return false
        return true

    }

    private getTransLocoMissingTransLationString(key, scopes: string) {
        let returnValue = scopes + "." + key
        return returnValue
    }



    public isLanguageImplemented(languageCode: string): boolean {
        const findResult = languages.find(lang => lang.id === languageCode)
        this.logger.log('isLanguageImplemented', languageCode, findResult)
        if (findResult) return true;
        return false
    }

    public getLanguageName(languageCode: string): string {
        const findResult = languages.find(lang => lang.id === languageCode)
        if (findResult) return findResult.name;;
        return ""

    }

    public getLanguageNameInEnglish(languageCode: string) {
        const findResult = languages.find(lang => lang.id === languageCode)
        if (findResult) return findResult.englishName;
        return ""

    }

    get local(): string {
        return this.locale.substring(0, 2);
    }
}
