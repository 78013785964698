import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";

@Injectable({
    providedIn: 'root',
})
export class ResponsiveService {
    smallScreen: boolean;
    belowMediumScreen: boolean;

    constructor(
        private logger: LoggerService,
        public breakpointObserver: BreakpointObserver
    ) { }

    public setBreakpointObserver() {
        this.breakpointObserver
            .observe(['(min-width: 675px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.smallScreen = false;
                    this.logger.log('Viewport is over smallScreen breakpoint!');
                } else {
                    this.smallScreen = true;
                    this.logger.log(
                        'Viewport is below smallScreen breakpoint!'
                    );
                }
            });
        this.breakpointObserver
            .observe(['(min-width: 950px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.belowMediumScreen = false;
                    this.logger.log(
                        'Viewport is over mediumScreen breakpoint!'
                    );
                } else {
                    this.belowMediumScreen = true;
                    this.logger.log(
                        'Viewport is below mediumScreen breakpoint!'
                    );
                }
            });
    }
    public isScreenSmall(): boolean {
        return this.smallScreen;
    }
    public isScreenBig(): boolean {
        return !this.smallScreen;
    }
    public isScreenBelowMedium(): boolean {
        return this.belowMediumScreen;
    }

    public getBigSmall(): string {
        if (this.isScreenSmall()) {
            //            this.logger.log('getBigSmall . small')
            return 'small-screen';
        } else {
            //          this.logger.log('getBigSmall . big')
            return 'big-screen';
        }
    }
}
