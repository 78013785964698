import { OptionConfigData, optionConfigMap } from "./option.configuration";

import { OptionTypeEnum } from "./option-type.class";
import { ChoiceEnum } from "../choice.class";


export class OptionConfigClass {
    private _optionConfigData: OptionConfigData;

    constructor(optionConfigData: OptionConfigData) {
        this._optionConfigData = { ...optionConfigData }
    }

    static newOptionConfigClass(optionConfigKey: string): OptionConfigClass {
        return new OptionConfigClass(optionConfigMap.get(optionConfigKey))
    }

    get isOfTypeDate(): boolean {
        return this._optionConfigData.questionType === OptionTypeEnum.date
    }

    get isOfTypeText(): boolean {
        return this._optionConfigData.questionType === OptionTypeEnum.text
    }

    get isOfTypeTextAndNumber(): boolean {
        return this._optionConfigData.questionType === OptionTypeEnum.textAndNumber
    }

    get pimaryAnswer(): 'none' | 'yes' | 'yesNo' | 'yesNoMaybe' | 'willJoin' | 'number' {
        return this._optionConfigData.pimaryAnswer;
    }

    get showBasedOnPrimaryAnswer(): ChoiceEnum[] | undefined {
        return this._optionConfigData.showBasedOnPrimaryAnswer;
    }

    get secondaryAnswer(): | 'slider' | 'text' | 'textAndNumber' | 'number' {
        return this._optionConfigData.secondaryAnswer;
    }

    get showNumberAnswer(): boolean {
        return this.secondaryAnswer === 'number' || this.secondaryAnswer === 'textAndNumber' || this.secondaryAnswer === 'slider'
    }

    get showTextAnswer(): boolean {
        return this.secondaryAnswer === 'text' || this.secondaryAnswer === 'textAndNumber'
    }

    get showMaxParticipants(): boolean {
        if (this._optionConfigData.showMaxParticipants !== true) return false;
        return true
    }

    get multipleSecondaryAnswers(): boolean | undefined {
        return this._optionConfigData.multipleSecondaryAnswers;
    }
    get icon(): string | undefined {
        return this._optionConfigData.icon;
    }

    get isParent(): boolean {
        if (this._optionConfigData.isParent) return true;
        return false
    }
    get allowToAddOptions(): boolean {
        if (this._optionConfigData.allowToAddOptions) return true;
        return false
    }


}
