
export interface ParticipationData {
    participatedUserId: string;
    userName?: string;
    ownerName?: string;
    ownerId: string;
    pollId: string;
    pollTitle: string;
    updatedAt?: any;
    createtAt?: any;
}

export class ParticipationClass {
    private _data: ParticipationData;

    get data(): ParticipationData {
        return this._data;
    }

    get participatedUserId(): string {
        return this._data.participatedUserId;
    }

    set participatedUserId(value: string) {
        this._data.participatedUserId = value;
    }

    get userName(): string | undefined {
        return this._data.userName;
    }

    set userName(value: string | undefined) {
        this._data.userName = value;
    }

    get ownerName(): string | undefined {
        return this._data.ownerName;
    }

    set ownerName(value: string | undefined) {
        this._data.ownerName = value;
    }

    get ownerId(): string {
        return this._data.ownerId;
    }

    set ownerId(value: string) {
        this._data.ownerId = value;
    }

    get pollId(): string {
        return this._data.pollId;
    }

    set pollId(value: string) {
        this._data.pollId = value;
    }

    get pollTitle(): string {
        return this._data.pollTitle;
    }

    set pollTitle(value: string) {
        this._data.pollTitle = value;
    }

    get updatedAt(): any {
        return this._data.updatedAt;
    }

    set updatedAt(value: any) {
        this._data.updatedAt = value;
    }

    get createtAt(): any {
        return this._data.createtAt;
    }

    set createtAt(value: any) {
        this._data.createtAt = value;
    }

    constructor(pollParticipation: ParticipationData) {
        this._data = { ...pollParticipation };
    }

    public unitialize() {
        this._data = null;
    }


    get stringify(): string {
        if (this._data) {
            return JSON.stringify(this._data);
        }
        return '';
    }
}
