<div class="component-container">

    <div class="text-box-container">
        <div class="faces-left-container">
            <div class="faces-left"></div>
        </div>
        <div class="text-box">


            <div class="text">
                <div class="quote-start-container">
                    <div class="quote-start"></div>
                </div>
                {{labelPrefix + 'text'| transPage }}
                <div class="quote-end-container">
                    <div class="quote-end"></div>
                </div>
            </div>
            <div class="picture-and-name-container">
                <div class="picture-and-name">
                    <div>
                        <img src="{{imageUrl}}" alt="{{getName()}}" width="100" height="100" loading="lazy">
                    </div>
                    <div class="name">{{getName()}}</div>
                    <div class="titel"> {{labelPrefix + 'titel'| transPage }}</div>
                </div>
            </div>
        </div>
        <div class="faces-right-container">
            <div class="faces-right"></div>
        </div>
    </div>

</div>