
export enum OptionTypeEnum {
    text = 'TEXT',
    textAndNumber = 'TEXT-NUMBER',
    date = 'DATE',
}


export class OptionTypeClass {
    private _optionTypeEnum: OptionTypeEnum; ß
    constructor(optionTypeEnum: OptionTypeEnum) {
        this._optionTypeEnum = optionTypeEnum;
    }

    get optionTypeEnum() {
        return this._optionTypeEnum
    }
    set optionTypeEnum(optionTypeEnum: OptionTypeEnum) {
        this._optionTypeEnum = optionTypeEnum
    }
    public isEqual(toCompareWith: OptionTypeClass): boolean {
        return this.optionTypeEnum === toCompareWith.optionTypeEnum
    }

    get isText(): boolean {
        return this.optionTypeEnum === OptionTypeEnum.text
    }

    get isDate(): boolean {
        return this.optionTypeEnum === OptionTypeEnum.date
    }

    get isTextAndNumber(): boolean {
        return this.optionTypeEnum === OptionTypeEnum.date
    }

    get isUndefined(): boolean {
        return !this.isDate && !this.isText && !this.isTextAndNumber

    }
}
