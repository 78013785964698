import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TranslateTemplatePipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { TranslateLandingPagePipe } from '../../../../../../../../dist/ngx-essentia';
@Component({
    selector: 'why-whocan',

    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        TranslateLandingPagePipe
    ],
    templateUrl: './why-whocan.component.html',
    styleUrls: ['./why-whocan.component.scss'],
})
export class WhyWhocanComponent {

    @Input() templateKey: string;
    @Input() translationKey1: string = 'landingPageWhyWhocan';
    constructor(public responsiveService: ResponsiveService) { }


    get labelPrefix() {
        return this.templateKey + '.' + this.translationKey1 + '.'
    }
}
